import React, { useState, useEffect } from "react";
import "./PageLinks.css";
import { Bars3Icon } from "@heroicons/react/24/outline"; // ✅ Correct for v2
import MenuButton from "../../utilities/MenuButton/MenuButton";
import pageLinks from "../../data/pageLinks";
import logo from "../../assets/logo-large (2).png"
import PrivacyNotice from "../../utilities/PrivacyNotice/PrivacyNotice";
import Disclaimer from "../../utilities/Disclaimer/Disclaimer";
import TermsOfServices from "../../utilities/TermsOfServices/TermsOfServices";
import EULA from "../../utilities/EULA/EULA";
import { Link } from "react-router-dom";
import GoTop from "../../utilities/GoTop/GoTop";
import { useLocation } from "react-router-dom";
function PageLinks() {
  const [slideOpacity, setSlideOpacity] = useState("");
  const [winWidth, setWinWidth] = useState(window.innerWidth);
  const routerLocation = useLocation();

  useEffect(() => {
    setWinWidth(window.innerWidth);
    if (winWidth > 550) {
      setSlideOpacity("1");
    } else {
      setSlideOpacity("0");
    }
  }, []);

  const toggleSidebar = () => {
    if (winWidth < 551) {
      setSlideOpacity((prev) => {
        if (prev === "0") {
          return "1";
        } else {
          return "0";
        }
      });
    }
  };

  useEffect(() => {
    const scrollToSection = () => {
      const path = routerLocation.pathname.replace("/", "");
      const section = document.getElementById(path);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    };
    scrollToSection();
  }, [routerLocation.pathname]);

  return (
    <>
      <div className="page-links" id="page-links-main">
        <GoTop />
        <div className="logo">
          <Link to="/">
            <img className="logo-img" src={logo} alt="" />
          </Link>
          <Bars3Icon onClick={toggleSidebar} className="menu-icon" />
        </div>
        <div className="main">
          <div style={{ opacity: slideOpacity }} className="sidebar">
            <div className="sidebar_main">
              {pageLinks.map((page, index) => (
                <MenuButton
                  key={index}
                  page={page}
                  index={index}
                  certainClass={"menuBtn" + index}
                  toggleSidebar={toggleSidebar}
                />
              ))}
            </div>
          </div>
          <div className="content-area">
            <div id="privacy">
              <PrivacyNotice />
            </div>
            <div id="terms">
              <TermsOfServices />
            </div>
            <div id="EULA">
              <EULA />
            </div>
            <div id="disclaimer">
              <Disclaimer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PageLinks;
