import React, { useEffect } from "react";
import "./MenuButton.css";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { HashLink } from "react-router-hash-link";

function MenuButton({ page, index, certainClass, toggleSidebar }) {
  useEffect(() => {
    const thisBtn = document.querySelector(`.${certainClass}`);
    const chevRight = document.querySelector(`.${certainClass} .chev-right`);
    if (index === 0) {
      thisBtn.classList.add("priv");
      chevRight.classList.add("priv-chev");
    } else if (index === 1) {
      thisBtn.classList.add("terms");
      chevRight.classList.add("terms-chev");
    } else {
      thisBtn.classList.add("disc");
      chevRight.classList.add("disc-chev");
    }
  }, []);

  const toggleBtn = (e) => {
    const buttonLists = document.querySelectorAll(".page-btn ul");
    const presentBtn = document.querySelector(`.${certainClass} ul`);
    if (presentBtn.classList.contains("activeList")) {
      presentBtn.classList.remove("activeList");
      removeChevRotate();
    } else {
      buttonLists.forEach((list) => {
        list.classList.remove("activeList");
      });
      removingAllButtonsStyle();
      removeChevRotate();
      presentBtn.classList.add("activeList");
      addingStyle();
      addChevRotate();
    }
  };

  const mouseOn = () => {
    addingStyle();
  };
  const mouseOut = () => {
    const presentBtn = document.querySelector(`.${certainClass} ul`);
    if (!presentBtn.classList.contains("activeList")) {
      removingStyle();
    }
  };

  const addingStyle = () => {
    const thisBtn = document.querySelector(`.${certainClass}`);
    const chevRight = document.querySelector(`.${certainClass} .chev-right`);
    if (index === 0) {
      thisBtn.classList.add("priv-hover");
      chevRight.classList.add("priv-hover-chev");
    } else if (index === 1) {
      thisBtn.classList.add("terms-hover");
      chevRight.classList.add("terms-hover-chev");
    } else {
      thisBtn.classList.add("disc-hover");
      chevRight.classList.add("disc-hover-chev");
    }
  };

  const removingStyle = () => {
    const thisBtn = document.querySelector(`.${certainClass}`);
    const chevRight = document.querySelector(`.${certainClass} .chev-right`);
    if (index === 0) {
      thisBtn.classList.remove("priv-hover");
      chevRight.classList.remove("priv-hover-chev");
    } else if (index === 1) {
      thisBtn.classList.remove("terms-hover");
      chevRight.classList.remove("terms-hover-chev");
    } else {
      thisBtn.classList.remove("disc-hover");
      chevRight.classList.remove("disc-hover-chev");
    }
  };

  const removingAllButtonsStyle = () => {
    const allBtns = document.querySelectorAll(`.page-btn`);
    const allChevRights = document.querySelectorAll(`.page-btn .chev-right`);

    allBtns.forEach((btn) => {
      if (btn.classList.contains("priv-hover")) {
        btn.classList.remove("priv-hover");
      } else if (btn.classList.contains("terms-hover")) {
        btn.classList.remove("terms-hover");
      } else {
        btn.classList.remove("disc-hover");
      }
    });

    allChevRights.forEach((btn) => {
      if (btn.classList.contains("priv-hover-chev")) {
        btn.classList.remove("priv-hover-chev", "chevRotate90");
      } else if (btn.classList.contains("terms-hover-chev")) {
        btn.classList.remove("terms-hover-chev", "chevRotate90");
      } else {
        btn.classList.remove("disc-hover-chev", "chevRotate90");
      }
    });
  };

  const addChevRotate = () => {
    const chevRight = document.querySelector(`.${certainClass} .chev-right`);
    if (!chevRight.classList.contains("chevRotate90")) {
      chevRight.classList.add("chevRotate90");
    }
  };
  const removeChevRotate = () => {
    const chevRight = document.querySelector(`.${certainClass} .chev-right`);
    if (chevRight.classList.contains("chevRotate90")) {
      chevRight.classList.remove("chevRotate90");
    }
  };
  return (
    <div
      onMouseOver={mouseOn}
      onMouseOut={mouseOut}
      onClick={toggleBtn}
      className={`page-btn ${certainClass}`}
    >
      <div className="wrapper">
        <span className="chev-right">
          <ChevronRightIcon className="chev-icon" />
        </span>
        <span className="page-name">{page.name}</span>
      </div>
      <ul className="menuList">
  {page.sub.map((qtn) => (
    <HashLink
      className="menu-btn-anchor"
      key={qtn.id}
      to={`/${page.id}#${qtn.id}`}
    >
      <li onClick={toggleSidebar} className="list-q">
        {qtn.topic}
      </li>
    </HashLink>
  ))}
</ul>
    </div>
  );
}

export default MenuButton;
