import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import ComingSoon from "./routes/home/ComingSoon";
import Footer from './components/Footer';
import PrivacyNotice from './utilities/PrivacyNotice/PrivacyNotice';
import PageLinks from './routes/PageLinks/PageLinks';

function App() {
  return (
    <div className="main-container">
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, flexDirection: 'column' }}>
       <Routes>
        <Route path="/" element={<ComingSoon />} />
        <Route path="/privacy" element={<PageLinks />} />
        <Route path="/terms" element={<PageLinks />} />
        <Route path="/eula" element={<PageLinks />} />
        <Route path="/disclaimer" element={<PageLinks />} />
      </Routes>
      <Footer />
      </div>
    </div>
  );
}

export default App;
