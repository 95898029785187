import { ChevronUpIcon } from "@heroicons/react/20/solid";
import React from "react";
import { HashLink } from "react-router-hash-link";
import "./ReturnToTop.css";

function ReturnToTop({ category, hashUrl }) {
  return (
    <div className="return-t-btn">
      <HashLink className="return-t-anchor" to={`/privacy#${hashUrl}`}>
        <div className="return-t">
          <div className="return-t_chev-up">
            <ChevronUpIcon className="chev-up" />
          </div>
          <div className="text slight-bold">Return to {category}</div>
        </div>
      </HashLink>
    </div>
  );
}

export default ReturnToTop;
