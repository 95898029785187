import React, {useCallback, useState} from 'react';
import './ComingSoon.css';
import LogoLarge from '../../../assets/logo-large.png';
import {toast} from 'react-toastify';
import {Container, Image} from "react-bootstrap";
import AppStoreIcon from '../../../assets/image.png'; // Adjust path as needed

const ComingSoon = () => {
    const [email, setEmail] = useState<string>('');
    const onChangeEmail = useCallback<React.ChangeEventHandler<HTMLInputElement>>(({target: {value}}) => {
        setEmail(value);
    }, []);
    const onSubmit = useCallback(() => {
        toast.promise(fetch('https://api.tycoonland.nyc/waitlist', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email,
            })
        }), {
            pending: "Submitting...",
            success: "Success! We'll keep you updated!",
            error: "Something went wrong...",
        })
            .then(res => {
                console.log("res: ", res);
                return res.json()
            })
            .then(res => {
                console.log("Json", res);
            })
            .catch(err => console.log(err))
            .finally(() => {
                setEmail('');
            })
    }, [email]);
    return (
        <Container>
            <div className="image-container">
                <Image alt="Tycoonland Logo" src={LogoLarge} className="logo" />
                <p style={{color: 'black', textAlign: "center"}}>
                Play and compete on Manhattan's free-market digital asset game!
                </p>
            </div>
        </Container>
    );
    
    
};

export default ComingSoon;
