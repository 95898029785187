import React from 'react';
import { Link } from "react-router-dom";
import "./Footer.css";
import AppStoreButton from './AppStoreButton';

export default function Footer() {
    return (
        <div className="links-div">
           {/* Main links section with App Store button */}
          {/* <div className='main-links'>
               <AppStoreButton width="140" height="43" />
            </div> */}

            <Link className="privacy-terms-link" to="/privacy">
                Privacy & Terms of Service
            </Link>
        </div>
    );
}
