import React from "react";
import "./PrivacyNotice.css";

import ReturnToTop from "../ReturnToTop/ReturnToTop";
import { HashLink } from "react-router-hash-link";

function PrivacyNotice() {
  return (
    <div className="content" id="privacy-content">
      {/* Heading */}
      <div className="content_heading">
        <h2>Privacy Notice</h2>
        {/* Updated date to match PDF */}
        <p>Last updated March 07, 2025</p>
      </div>

      {/* Intro */}
      <div className="content_intro">
        <p>
          This Privacy Notice for Tycoonland, llc ("<span className="slight-bold">we</span>,"
          "<span className="slight-bold">us</span>," or "
          <span className="slight-bold">our</span>"), describes how and why we
          might access, collect, store, use, and/or share ("<span className="slight-bold">process</span>") your
          personal information when you use our services ("<span className="slight-bold">Services</span>"),
          including when you:
        </p>
        <ul>
          <li>
            Visit our website at{" "}
            <a className="content-link" href="https://www.tycoonland.nyc">
              https://www.tycoonland.nyc
            </a>
            , or any website of ours that links to this Privacy Notice
          </li>
          <li>
            Download and use our mobile application (Tycoonland), or any other
            application of ours that links to this Privacy Notice
          </li>
          <li>
            Use Tycoonland. Tycoonland is an innovative simulation that allows
            players to immerse themselves into the fast-paced world of New York
            City's real estate market. Players take on the role of budding real
            estate tycoons, navigating the complexities of buying, selling, and
            developing properties across Manhattan's iconic neighborhoods. The
            Game offers a realistic portrayal of the challenges and opportunities
            faced by real estate investors in the financial capital of the world.
            Players must make strategic decisions to maximize profits, negotiate
            deals with savvy competitors, and manage their investments wisely to
            build a lucrative portfolio. Featuring detailed graphics and dynamic
            gameplay, Tycoonland captures the essence of Manhattan's skyline,
            from historic brownstones to gleaming skyscrapers. Whether buying up
            luxury condos in Tribeca or revitalizing properties in Harlem, players
            can experience the thrill of real estate entrepreneurship from the
            comfort of their own mobile devices. With multiplayer options, players
            can also compete against friends or collaborate to dominate the
            market together. Tycoonland is a must-play game for anyone fascinated
            by keen strategies of finance, investment, and competition in America's
            most vibrant cities.
          </li>
          <li>Engage with us in other related ways, including any sales, marketing, or events</li>
        </ul>
        <p>
          <span className="slight-bold">Questions or concerns?</span> Reading
          this Privacy Notice will help you understand your privacy rights and
          choices. We are responsible for making decisions about how your personal
          information is processed. If you do not agree with our policies and
          practices, please do not use our Services. If you still have any questions
          or concerns, please contact us at support@tycoonland.nyc.
        </p>
      </div>

      {/* Summary Of Key Points */}
      <div className="content_key-points">
        <h2>Summary of Key Points</h2>
        <p className="intro">
          <span className="slight-bold">
            This summary provides key points from our Privacy Notice, but you can
            find out more details about any of these topics by reading the full text
            below.
          </span>
        </p>
        <p>
          <span className="slight-bold">What personal information do we process?</span>{" "}
          When you visit, use, or navigate our Services, we may process personal
          information depending on how you interact with us and the Services, the
          choices you make, and the products and features you use. Learn more in
          Section 1.
        </p>
        <p>
          <span className="slight-bold">Do we process any sensitive personal information?</span>{" "}
          Some of the information may be considered “special” or “sensitive” in
          certain jurisdictions (for example, your racial or ethnic origins,
          sexual orientation, or religious beliefs). We may process sensitive
          personal information when necessary with your consent or as otherwise
          permitted by applicable law. See Section 1 for more details.
        </p>
        <p>
          <span className="slight-bold">Do we collect any information from third parties?</span>{" "}
          We do not collect any information from third parties.
        </p>
        <p>
          <span className="slight-bold">How do we process your information?</span> We
          process your information to provide, improve, and administer our Services,
          communicate with you, for security and fraud prevention, and to comply
          with law. We may also process your information for other purposes with
          your consent. See Section 2.
        </p>
        <p>
          <span className="slight-bold">In what situations and with which parties do we share personal information?</span>{" "}
          We may share information in specific situations and with specific third
          parties. See Section 4.
        </p>
        <p>
          <span className="slight-bold">How do we keep your information safe?</span>{" "}
          We have adequate organizational and technical processes and procedures
          in place to protect your personal information. However, no electronic
          transmission can be guaranteed to be 100% secure. See Section 7.
        </p>
        <p>
          <span className="slight-bold">What are your rights?</span> Depending on
          where you are located geographically, the applicable privacy law may mean
          you have certain rights regarding your personal information. See Section 8.
        </p>
        <p>
          <span className="slight-bold">How do you exercise your rights?</span> You can
          submit a data subject access request or contact us. We will consider and
          act upon any request in accordance with applicable data protection laws.
        </p>
        <p>
          Want to learn more about what we do with any information we collect?
          Continue reading the full notice below.
        </p>
      </div>

      <ReturnToTop hashUrl={"privacy-content"} category={"Privacy Notice"} />

      {/* Table Of Contents */}
      <div className="content_intro">
        <h2>Table of Contents</h2>
        <ol>
          <li>
            <HashLink className="content-link" to="#info-collect">
              1. WHAT INFORMATION DO WE COLLECT?
            </HashLink>
          </li>
          <li>
            <HashLink className="content-link" to="#process-info">
              2. HOW DO WE PROCESS YOUR INFORMATION?
            </HashLink>
          </li>
          <li>
            <HashLink className="content-link" to="#legal-bases">
              3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?
            </HashLink>
          </li>
          <li>
            <HashLink className="content-link" to="#share-info">
              4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </HashLink>
          </li>
          <li>
            <HashLink className="content-link" to="#transferred-internationally">
              5. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
            </HashLink>
          </li>
          <li>
            <HashLink className="content-link" to="#keep-info">
              6. HOW LONG DO WE KEEP YOUR INFORMATION?
            </HashLink>
          </li>
          <li>
            <HashLink className="content-link" to="#info-safe">
              7. HOW DO WE KEEP YOUR INFORMATION SAFE?
            </HashLink>
          </li>
          <li>
            <HashLink className="content-link" to="#privacy-rights">
              8. WHAT ARE YOUR PRIVACY RIGHTS?
            </HashLink>
          </li>
          <li>
            <HashLink className="content-link" to="#do-not-track">
              9. CONTROLS FOR DO-NOT-TRACK FEATURES
            </HashLink>
          </li>
          <li>
            <HashLink className="content-link" to="#us-privacy-rights">
              10. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </HashLink>
          </li>
          <li>
            <HashLink className="content-link" to="#other-regions-rights">
              11. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?
            </HashLink>
          </li>
          <li>
            <HashLink className="content-link" to="#account-deletion">
              12. ACCOUNT DELETION/DIGITAL ASSET FORFEITURES
            </HashLink>
          </li>
          <li>
            <HashLink className="content-link" to="#make-updates">
              13. DO WE MAKE UPDATES TO THIS NOTICE?
            </HashLink>
          </li>
          <li>
            <HashLink className="content-link" to="#contact-us">
              14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </HashLink>
          </li>
          <li>
            <HashLink className="content-link" to="#data-you">
              15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
            </HashLink>
          </li>
        </ol>
      </div>

      <ReturnToTop hashUrl={"privacy-content"} category={"Privacy Notice"} />

      {/* 1. WHAT INFORMATION DO WE COLLECT? */}
      <div className="content_info-collect each-section" id="info-collect">
        <h2 className="heading-h2">1. WHAT INFORMATION DO WE COLLECT?</h2>
        <h3 className="heading-h3">Personal information you disclose to us</h3>
        <p className="in-short">
          <i>
            <span className="slight-bold">In Short:</span> We collect personal
            information that you provide to us.
          </i>
        </p>
        <p>
          We collect personal information that you voluntarily provide to us when
          you register on the Services, express an interest in obtaining
          information about us or our products and Services, when you participate
          in activities on the Services, or otherwise when you contact us.
        </p>
        <p>
          <span className="slight-bold">Personal Information Provided by You.</span> The
          personal information that we collect depends on the context of your
          interactions with us and the Services, the choices you make, and the
          products and features you use. The personal information we collect may
          include the following:
        </p>
        <ul>
          <li>names</li>
          <li>phone numbers</li>
          <li>email addresses</li>
          <li>mailing addresses</li>
          <li>usernames</li>
          <li>passwords</li>
          <li>contact or authentication data</li>
          <li>debit/credit card numbers</li>
        </ul>
        <p>
          <span className="slight-bold">Sensitive Information.</span> When necessary,
          with your consent or as otherwise permitted by applicable law, we
          process the following categories of sensitive information:
        </p>
        <ul>
          <li>financial data</li>
        </ul>
        <p>
          <span className="slight-bold">Payment Data.</span> We may collect data
          necessary to process your payment if you choose to make purchases, such
          as your payment instrument number, and the security code associated
          with your payment instrument. All payment data is handled and stored by
          Stripe. You may find their privacy notice at:{" "}
          <a
            className="content-link"
            href="https://stripe.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            https://stripe.com/privacy
          </a>
          .
        </p>
        <p>
          <span className="slight-bold">Application Data.</span> If you use our
          application(s), we also may collect the following information if you
          choose to provide us with access or permission:
        </p>
        <ul>
          <li>
            <span className="slight-bold">Push Notifications.</span> We may request to
            send you push notifications regarding your account or certain features
            of the application(s). If you wish to opt out from receiving these types
            of communications, you may turn them off in your device's settings.
          </li>
        </ul>
        <p>
          This information is primarily needed to maintain the security and
          operation of our application(s), for troubleshooting, and for our
          internal analytics and reporting purposes.
        </p>
        <p>
          All personal information that you provide to us must be true, complete,
          and accurate, and you must notify us of any changes to such personal
          information.
        </p>

        <h3 className="heading-h3">Information automatically collected</h3>
        <p>
          <i>
            <span className="slight-bold">In Short:</span> Some information — such
            as your Internet Protocol (IP) address and/or browser and device
            characteristics — is collected automatically when you visit our
            Services.
          </i>
        </p>
        <p>
          We automatically collect certain information when you visit, use, or
          navigate the Services. This information does not reveal your specific
          identity (like your name or contact information) but may include device
          and usage information, such as your IP address, browser and device
          characteristics, operating system, language preferences, referring URLs,
          device name, country, location, information about how and when you use
          our Services, and other technical information. This information is
          primarily needed to maintain the security and operation of our Services,
          and for our internal analytics and reporting purposes.
        </p>
        <p>The information we collect includes:</p>
        <ul>
          <li>
            <span className="slight-bold">Log and Usage Data.</span> Log and usage data
            is service-related, diagnostic, usage, and performance information our
            servers automatically collect when you access or use our Services and
            which we record in log files. Depending on how you interact with us,
            this log data may include your IP address, device information, browser
            type, and settings and information about your activity in the Services
            (such as the date/time stamps associated with your usage, pages and
            files viewed, searches, and other actions you take such as which
            features you use), device event information (such as system activity,
            error reports (sometimes called "crash dumps"), and hardware settings).
          </li>
          <li>
            <span className="slight-bold">Device Data.</span> We collect device data such
            as information about your computer, phone, tablet, or other device you
            use to access the Services. Depending on the device used, this device
            data may include information such as your IP address (or proxy
            server), device and application identification numbers, location,
            browser type, hardware model, Internet service provider and/or mobile
            carrier, operating system, and system configuration information.
          </li>
          <li>
            <span className="slight-bold">Location Data.</span> We collect location data
            such as information about your device's location, which can be either
            precise or imprecise. How much information we collect depends on the
            type and settings of the device you use to access the Services. For
            example, we may use GPS and other technologies to collect geolocation
            data that tells us your current location (based on your IP address).
            You can opt out of allowing us to collect this information either by
            refusing access to the information or by disabling your Location
            setting on your device. However, if you choose to opt out, you may not
            be able to use certain aspects of the Services.
          </li>
        </ul>
      </div>

      <ReturnToTop hashUrl={"privacy-content"} category={"Privacy Notice"} />

      {/* 2. HOW DO WE PROCESS YOUR INFORMATION? */}
      <div className="content_process-info each-section" id="process-info">
        <h2 className="heading-h2">2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
        <p>
          <i>
            <span className="slight-bold">In Short:</span> We process your
            information to provide, improve, and administer our Services,
            communicate with you, for security and fraud prevention, and to comply
            with law. We may also process your information for other purposes with
            your consent.
          </i>
        </p>
        <p>
          We process your personal information for a variety of reasons, depending
          on how you interact with our Services, including:
        </p>
        <ul>
          <li>
            <span className="slight-bold">
              To facilitate account creation and authentication and otherwise manage
              user accounts.
            </span>{" "}
            We may process your information so you can create and log in to your
            account, as well as keep your account in working order.
          </li>
          <li>
            <span className="slight-bold">
              To deliver and facilitate delivery of services to the user.
            </span>{" "}
            We may process your information to provide you with the requested
            service.
          </li>
          <li>
            <span className="slight-bold">
              To respond to user inquiries/offer support to users.
            </span>{" "}
            We may process your information to respond to your inquiries and solve
            any potential issues you might have with the requested service.
          </li>
          <li>
            <span className="slight-bold">To send administrative information to you.</span>{" "}
            We may process your information to send you details about our products
            and services, changes to our terms and policies, and other similar
            information.
          </li>
          <li>
            <span className="slight-bold">To fulfill and manage your orders.</span> We may
            process your information to fulfill and manage your orders, payments,
            returns, and exchanges made through the Services.
          </li>
          <li>
            <span className="slight-bold">To enable user-to-user communications.</span> We
            may process your information if you choose to use any of our offerings
            that allow for communication with another user.
          </li>
          <li>
            <span className="slight-bold">To request feedback.</span> We may process your
            information when necessary to request feedback and to contact you about
            your use of our Services.
          </li>
          <li>
            <span className="slight-bold">
              To send you marketing and promotional communications.
            </span>{" "}
            We may process the personal information you send to us for our
            marketing purposes, if this is in accordance with your marketing
            preferences. You can opt out of our marketing emails at any time. For
            more information, see “WHAT ARE YOUR PRIVACY RIGHTS?” below.
          </li>
          <li>
            <span className="slight-bold">
              To deliver targeted advertising to you.
            </span>{" "}
            We may process your information to develop and display personalized
            content and advertising tailored to your interests, location, and more.
          </li>
          <li>
            <span className="slight-bold">To protect our Services.</span> We may process
            your information as part of our efforts to keep our Services safe and
            secure, including fraud monitoring and prevention.
          </li>
          <li>
            <span className="slight-bold">To identify usage trends.</span> We may process
            information about how you use our Services to better understand how
            they are being used so we can improve them.
          </li>
          <li>
            <span className="slight-bold">
              To determine the effectiveness of our marketing and promotional
              campaigns.
            </span>{" "}
            We may process your information to better understand how to provide
            marketing and promotional campaigns that are most relevant to you.
          </li>
          <li>
            <span className="slight-bold">
              To save or protect an individual's vital interest.
            </span>{" "}
            We may process your information when necessary to save or protect an
            individual’s vital interest, such as to prevent harm.
          </li>
        </ul>
      </div>

      <ReturnToTop hashUrl={"privacy-content"} category={"Privacy Notice"} />

      {/* 3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION? */}
      <div className="each-section" id="legal-bases">
        <h2 className="heading-h2">
          3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?
        </h2>
        <p>
          <i>
            <span className="slight-bold">In Short:</span> We only process your
            personal information when we believe it is necessary and we have a
            valid legal reason (i.e., legal basis) to do so under applicable law,
            like with your consent, to comply with laws, to provide you with
            services to enter into or fulfill our contractual obligations, to
            protect your rights, or to fulfill our legitimate business interests.
          </i>
        </p>
        <p>
          If you are located in the EU or UK, this section applies to you. The
          General Data Protection Regulation (GDPR) and UK GDPR require us to
          explain the valid legal bases we rely on in order to process your
          personal information. As such, we may rely on the following legal bases:
        </p>
        <ul>
          <li>
            <span className="slight-bold">Consent.</span> We may process your
            information if you have given us permission (i.e., consent) to use your
            personal information for a specific purpose. You can withdraw your
            consent at any time.
          </li>
          <li>
            <span className="slight-bold">Performance of a Contract.</span> We may
            process your personal information when we believe it is necessary to
            fulfill our contractual obligations to you, including providing our
            Services or at your request prior to entering into a contract with you.
          </li>
          <li>
            <span className="slight-bold">Legitimate Interests.</span> We may process
            your information when we believe it is reasonably necessary to achieve
            our legitimate business interests and those interests do not outweigh
            your interests and fundamental rights and freedoms. For example, we
            may process your personal information for some of the purposes
            described in order to:
            <ul>
              <li>
                Send users information about special offers and discounts on our
                products and services
              </li>
              <li>
                Develop and display personalized and relevant advertising content
                for our users
              </li>
              <li>Analyze how our Services are used so we can improve them</li>
              <li>Support our marketing activities</li>
              <li>Diagnose problems and/or prevent fraudulent activities</li>
              <li>
                Understand how our users use our products and services so we can
                improve user experience
              </li>
            </ul>
          </li>
          <li>
            <span className="slight-bold">Legal Obligations.</span> We may process your
            information where we believe it is necessary for compliance with our
            legal obligations, such as to cooperate with a law enforcement body or
            regulatory agency, exercise or defend our legal rights, or disclose
            your information as evidence in litigation in which we are involved.
          </li>
          <li>
            <span className="slight-bold">Vital Interests.</span> We may process your
            information where we believe it is necessary to protect your vital
            interests or the vital interests of a third party, such as situations
            involving potential threats to the safety of any person.
          </li>
        </ul>
        <p>
          If you are located in Canada, this section applies to you. We may process
          your information if you have given us specific permission (i.e., express
          consent) to use your personal information for a specific purpose, or in
          situations where your permission can be inferred (i.e., implied consent).
          You can withdraw your consent at any time.
        </p>
        <p>
          In some exceptional cases, we may be legally permitted under applicable
          law to process your information without your consent, including, for
          example:
        </p>
        <ul>
          <li>
            If collection is clearly in the interests of an individual and consent
            cannot be obtained in a timely way
          </li>
          <li>For investigations and fraud detection and prevention</li>
          <li>For business transactions provided certain conditions are met</li>
          <li>
            If it is contained in a witness statement and the collection is
            necessary to assess, process, or settle an insurance claim
          </li>
          <li>
            For identifying injured, ill, or deceased persons and communicating with
            next of kin
          </li>
          <li>
            If we have reasonable grounds to believe an individual has been, is, or
            may be victim of financial abuse
          </li>
          <li>
            If it is reasonable to expect collection and use with consent would
            compromise the availability or the accuracy of the information and the
            collection is reasonable for purposes related to investigating a breach
            of an agreement or a contravention of the laws of Canada or a province
          </li>
          <li>
            If disclosure is required to comply with a subpoena, warrant, court
            order, or rules of the court relating to the production of records
          </li>
          <li>
            If it was produced by an individual in the course of their employment,
            business, or profession and the collection is consistent with the
            purposes for which the information was produced
          </li>
          <li>
            If the collection is solely for journalistic, artistic, or literary
            purposes
          </li>
          <li>
            If the information is publicly available and is specified by the
            regulations
          </li>
        </ul>
      </div>

      <ReturnToTop hashUrl={"privacy-content"} category={"Privacy Notice"} />

      {/* 4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION? */}
      <div className="content_share-info each-section" id="share-info">
        <h2 className="heading-h2">
          4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </h2>
        <p>
          <i>
            <span className="slight-bold">In Short:</span> We may share
            information in specific situations described in this section and/or
            with the following third parties.
          </i>
        </p>
        <p>
          We may need to share your personal information in the following
          situations:
        </p>
        <ul>
          <li>
            <span className="slight-bold">Business Transfers.</span> We may share or
            transfer your information in connection with, or during negotiations
            of, any merger, sale of company assets, financing, or acquisition of
            all or a portion of our business to another company.
          </li>
          <li>
            <span className="slight-bold">Affiliates.</span> We may share your
            information with our affiliates, in which case we will require those
            affiliates to honor this Privacy Notice. Affiliates include our parent
            company and any subsidiaries, joint venture partners, or other
            companies that we control or that are under common control with us.
          </li>
          <li>
            <span className="slight-bold">Business Partners.</span> We may share your
            information with our business partners to offer you certain products,
            services, or promotions.
          </li>
          <li>
            <span className="slight-bold">Offer Wall.</span> Our application(s) may
            display a third-party hosted “offer wall.” Such an offer wall allows
            third-party advertisers to offer virtual currency, gifts, or other
            items to users in return for the acceptance and completion of an
            advertisement offer. Such an offer wall may appear in our application(s)
            and be displayed to you based on certain data, such as your geographic
            area or demographic information. When you click on an offer wall, you
            will be brought to an external website belonging to other persons and
            will leave our application(s). A unique identifier, such as your user
            ID, will be shared with the offer wall provider in order to prevent
            fraud and properly credit your account with the relevant reward.
          </li>
        </ul>
      </div>

      <ReturnToTop hashUrl={"privacy-content"} category={"Privacy Notice"} />

      {/* 5. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY? */}
      <div className="each-section" id="transferred-internationally">
        <h2 className="heading-h2">5. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h2>
        <p>
          <i>
            <span className="slight-bold">In Short:</span> We may transfer, store,
            and process your information in countries other than your own.
          </i>
        </p>
        <p>
          Our servers are located in the United States. If you are accessing our
          Services from outside the United States, please be aware that your
          information may be transferred to, stored by, and processed by us in our
          facilities and in the facilities of the third parties with whom we may
          share your personal information (see “WHEN AND WITH WHOM DO WE SHARE
          YOUR PERSONAL INFORMATION?” above), in the United States, and other
          countries.
        </p>
        <p>
          If you are a resident in the European Economic Area (EEA), United
          Kingdom (UK), or Switzerland, then these countries may not necessarily
          have data protection laws or other similar laws as comprehensive as
          those in your country. However, we will take all necessary measures to
          protect your personal information in accordance with this Privacy Notice
          and applicable law.
        </p>
      </div>

      <ReturnToTop hashUrl={"privacy-content"} category={"Privacy Notice"} />

      {/* 6. HOW LONG DO WE KEEP YOUR INFORMATION? */}
      <div className="content_keep-info each-section" id="keep-info">
        <h2 className="heading-h2">6. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
        <p>
          <i>
            <span className="slight-bold">In Short:</span> We keep your information
            for as long as necessary to fulfill the purposes outlined in this
            Privacy Notice unless otherwise required by law.
          </i>
        </p>
        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this Privacy Notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice will
          require us keeping your personal information for longer than the period
          of time in which users have an account with us.
        </p>
        <p>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any further
          processing until deletion is possible.
        </p>
      </div>

      <ReturnToTop hashUrl={"privacy-content"} category={"Privacy Notice"} />

      {/* 7. HOW DO WE KEEP YOUR INFORMATION SAFE? */}
      <div className="content_info-safe each-section" id="info-safe">
        <h2 className="heading-h2">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
        <p>
          <i>
            <span className="slight-bold">In Short:</span> We aim to protect your
            personal information through a system of organizational and technical
            security measures.
          </i>
        </p>
        <p>
          We have implemented appropriate and reasonable technical and
          organizational security measures designed to protect the security of any
          personal information we process. However, despite our safeguards and
          efforts to secure your information, no electronic transmission over the
          Internet or information storage technology can be guaranteed to be 100%
          secure, so we cannot promise or guarantee that hackers, cybercriminals,
          or other unauthorized third parties will not be able to defeat our
          security and improperly collect, access, steal, or modify your
          information. Although we will do our best to protect your personal
          information, transmission of personal information to and from our
          Services is at your own risk. You should only access the Services within
          a secure environment.
        </p>
      </div>

      <ReturnToTop hashUrl={"privacy-content"} category={"Privacy Notice"} />

      {/* 8. WHAT ARE YOUR PRIVACY RIGHTS? */}
      <div className="content_privacy-rights each-section" id="privacy-rights">
        <h2 className="heading-h2">8. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
        <p>
          <i>
            <span className="slight-bold">In Short:</span> Depending on your state of
            residence in the US or in some regions, such as the European Economic
            Area (EEA), United Kingdom (UK), Switzerland, and Canada, you have
            rights that allow you greater access to and control over your personal
            information.
          </i>
        </p>
        <p>
          In some regions (like the EEA, UK, Switzerland, and Canada), you have
          certain rights under applicable data protection laws. These may include
          the right (i) to request access and obtain a copy of your personal
          information, (ii) to request rectification or erasure; (iii) to restrict
          the processing of your personal information; (iv) if applicable, to data
          portability; and (v) not to be subject to automated decision-making. In
          certain circumstances, you may also have the right to object to the
          processing of your personal information. You can make such a request by
          contacting us by using the contact details provided in the section “HOW
          CAN YOU CONTACT US ABOUT THIS NOTICE?” below.
        </p>
        <p>
          We will consider and act upon any request in accordance with applicable
          data protection laws.
        </p>
        <p>
          If you are located in the EEA or UK and you believe we are unlawfully
          processing your personal information, you also have the right to complain
          to your Member State data protection authority or UK data protection
          authority. If you are located in Switzerland, you may contact the Federal
          Data Protection and Information Commissioner.
        </p>
        <p>
          <span className="slight-bold">Withdrawing your consent:</span> If we are
          relying on your consent to process your personal information, which may
          be express and/or implied consent depending on the applicable law, you
          have the right to withdraw your consent at any time. You can withdraw
          your consent at any time by contacting us by using the contact details
          provided in the section “HOW CAN YOU CONTACT US ABOUT THIS NOTICE?” or
          by updating your preferences.
        </p>
        <p>
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal nor, when applicable law allows, will it
          affect the processing of your personal information conducted in reliance
          on lawful processing grounds other than consent.
        </p>
        <h3>Account Information</h3>
        <p>
          If you would at any time like to review or change the information in your
          account or terminate your account, you can:
        </p>
        <ul>
          <li>Log in to your account settings and update your user account.</li>
        </ul>
        <p>
          Upon your request to terminate your account, we will deactivate or delete
          your account and information from our active databases. However, we may
          retain some information in our files to prevent fraud, troubleshoot
          problems, assist with any investigations, enforce our legal terms, and/or
          comply with applicable legal requirements.
        </p>
        <p>
          If you have questions or comments about your privacy rights, you may
          email us at support@tycoonland.nyc.
        </p>
      </div>

      <ReturnToTop hashUrl={"privacy-content"} category={"Privacy Notice"} />

      {/* 9. CONTROLS FOR DO-NOT-TRACK FEATURES */}
      <div className="content_do-not-track each-section" id="do-not-track">
        <h2 className="heading-h2">9. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track (“DNT”) feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage, no
          uniform technology standard for recognizing and implementing DNT signals
          has been finalized. As such, we do not currently respond to DNT browser
          signals or any other mechanism that automatically communicates your
          choice not to be tracked online. If a standard for online tracking is
          adopted that we must follow in the future, we will inform you about that
          practice in a revised version of this Privacy Notice.
        </p>
        <p>
          California law requires us to let you know how we respond to web browser
          DNT signals. Because there currently is not an industry or legal standard
          for recognizing or honoring DNT signals, we do not respond to them at
          this time.
        </p>
      </div>

      <ReturnToTop hashUrl={"privacy-content"} category={"Privacy Notice"} />

      {/* 10. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS? */}
      <div className="each-section" id="us-privacy-rights">
        <h2 className="heading-h2">
          10. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </h2>
        <p>
          <i>
            <span className="slight-bold">In Short:</span> If you are a resident
            of California, Colorado, Connecticut, Delaware, Florida, Indiana, Iowa,
            Kentucky, Minnesota, Montana, Nebraska, New Hampshire, New Jersey,
            Oregon, Tennessee, Texas, Utah, or Virginia, you may have the right to
            request access to and receive details about the personal information we
            maintain about you and how we have processed it, correct inaccuracies,
            get a copy of, or delete your personal information. You may also have
            the right to withdraw your consent to our processing of your personal
            information. These rights may be limited in some circumstances by
            applicable law.
          </i>
        </p>
        <p>
          <span className="slight-bold">Categories of Personal Information We Collect</span>
          <br />
          We have collected the following categories of personal information in
          the past twelve (12) months:
        </p>

        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Category</th>
              <th>Examples</th>
              <th>Collected</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>A. Identifiers</td>
              <td>
                Contact details, such as real name, alias, postal address,
                telephone or mobile contact number, unique personal identifier,
                online identifier, Internet Protocol address, email address, and
                account name
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>
                B. Personal information as defined in the California Customer
                Records statute
              </td>
              <td>
                Name, contact information, education, employment, employment
                history, and financial information
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>
                C. Protected classification characteristics under state or federal
                law
              </td>
              <td>
                Gender, age, date of birth, race and ethnicity, national origin,
                marital status, and other demographic data
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <td>D. Commercial information</td>
              <td>
                Transaction information, purchase history, financial details, and
                payment information
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <td>E. Biometric information</td>
              <td>Fingerprints and voiceprints</td>
              <td>NO</td>
            </tr>
            <tr>
              <td>F. Internet or other similar network activity</td>
              <td>
                Browsing history, search history, online behavior, interest data,
                and interactions with our and other websites, applications,
                systems, and advertisements
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <td>G. Geolocation data</td>
              <td>Device location</td>
              <td>NO</td>
            </tr>
            <tr>
              <td>H. Audio, electronic, sensory, or similar information</td>
              <td>
                Images and audio, video or call recordings created in connection
                with our business activities
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <td>I. Professional or employment-related information</td>
              <td>
                Business contact details in order to provide you our Services at a
                business level or job title, work history, and professional
                qualifications if you apply for a job with us
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <td>J. Education Information</td>
              <td>Student records and directory information</td>
              <td>NO</td>
            </tr>
            <tr>
              <td>K. Inferences drawn from collected personal information</td>
              <td>
                Inferences drawn from any of the collected personal information
                listed above to create a profile or summary about, for example, an
                individual’s preferences and characteristics
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <td>L. Sensitive personal Information</td>
              <td>
                Account login information, debit or credit card numbers and
                personal data from a known child
              </td>
              <td>YES</td>
            </tr>
          </tbody>
        </table>

        <p>
          We only collect sensitive personal information, as defined by applicable
          privacy laws or the purposes allowed by law or with your consent.
          Sensitive personal information may be used, or disclosed to a service
          provider or contractor, for additional, specified purposes. You may have
          the right to limit the use or disclosure of your sensitive personal
          information. We do not collect or process sensitive personal information
          for the purpose of inferring characteristics about you.
        </p>
        <p>
          We may also collect other personal information outside of these
          categories through instances where you interact with us in person,
          online, or by phone or mail in the context of:
        </p>
        <ul>
          <li>Receiving help through our customer support channels;</li>
          <li>Participation in customer surveys or contests; and</li>
          <li>
            Facilitation in the delivery of our Services and to respond to your
            inquiries.
          </li>
        </ul>
        <p>
          We will use and retain the collected personal information as needed to
          provide the Services or for:
        </p>
        <ul>
          <li>Category A - As long as the user has an account with us</li>
          <li>Category B - As long as the user has an account with us</li>
          <li>Category H - As long as the user has an account with us</li>
          <li>Category L - As long as the user has an account with us</li>
        </ul>
        <p>
          <span className="slight-bold">Sources of Personal Information.</span> See
          Section 1 of this Notice.
        </p>
        <p>
          <span className="slight-bold">How We Use and Share Personal Information.</span>{" "}
          See Sections 2 and 4 of this Notice.
        </p>
        <p>
          <span className="slight-bold">Will your information be shared with anyone else?</span>{" "}
          We may disclose your personal information with our service providers
          pursuant to a written contract between us and each service provider. We
          may use your personal information for our own business purposes, such
          as for undertaking internal research for technological development and
          demonstration. This is not considered to be “selling” of your personal
          information.
        </p>
        <p>
          We have not disclosed, sold, or shared any personal information to third
          parties for a business or commercial purpose in the preceding twelve (12)
          months. We will not sell or share personal information in the future
          belonging to website visitors, users, and other consumers.
        </p>
        <p>
          <span className="slight-bold">Your Rights.</span> You have rights under
          certain US state data protection laws. However, these rights are not
          absolute, and in certain cases, we may decline your request as permitted
          by law. These rights include:
        </p>
        <ul>
          <li>Right to know whether or not we are processing your personal data</li>
          <li>Right to access your personal data</li>
          <li>Right to correct inaccuracies in your personal data</li>
          <li>Right to request the deletion of your personal data</li>
          <li>Right to obtain a copy of the personal data you previously shared with us</li>
          <li>Right to non-discrimination for exercising your rights</li>
          <li>
            Right to opt out of the processing of your personal data if it is used
            for targeted advertising (or “sharing” under California law), the sale
            of personal data, or profiling in furtherance of decisions that produce
            legal or similarly significant effects (“profiling”)
          </li>
        </ul>
        <p>
          Depending upon the state where you live, you may also have additional
          rights, such as:
        </p>
        <ul>
          <li>Right to access the categories of personal data being processed</li>
          <li>
            Right to obtain a list of the categories of third parties to which we
            have disclosed personal data
          </li>
          <li>
            Right to obtain a list of specific third parties to which we have
            disclosed personal data
          </li>
          <li>
            Right to review, understand, question, and correct how personal data
            has been profiled
          </li>
          <li>
            Right to limit use and disclosure of sensitive personal data (as
            permitted by applicable law)
          </li>
          <li>
            Right to opt out of the collection of sensitive data and personal data
            collected through a voice or facial recognition feature
          </li>
        </ul>
        <p>
          <span className="slight-bold">How to Exercise Your Rights:</span> To exercise
          these rights, you can contact us by submitting a data subject access
          request or by emailing us at support@tycoonland.nyc. We will honor your
          opt-out preferences if you enact the Global Privacy Control (GPC)
          opt-out signal on your browser.
        </p>
        <p>
          Under certain US state data protection laws, you can designate an
          authorized agent to make a request on your behalf. We may deny a request
          from an authorized agent that does not submit proof that they have been
          validly authorized to act on your behalf in accordance with applicable
          laws.
        </p>
        <p>
          <span className="slight-bold">Request Verification:</span> Upon receiving
          your request, we will need to verify your identity to determine you are
          the same person about whom we have the information in our system. We
          will only use personal information provided in your request to verify
          your identity or authority to make the request. However, if we cannot
          verify your identity from the information already maintained by us, we
          may request that you provide additional information for the purposes of
          verifying your identity and for security or fraud-prevention purposes.
        </p>
        <p>
          If you submit the request through an authorized agent, we may need to
          collect additional information to verify your identity before processing
          your request, and the agent will need to provide a written and signed
          permission from you to submit such request on your behalf.
        </p>
        <p>
          <span className="slight-bold">Appeals:</span> Under certain US state data
          protection laws, if we decline to take action regarding your request, you
          may appeal our decision by emailing us at support@tycoonland.nyc. We
          will inform you in writing of any action taken or not taken in response
          to the appeal, including a written explanation of the reasons for the
          decisions. If your appeal is denied, you may submit a complaint to your
          state attorney general.
        </p>
        <p>
          <span className="slight-bold">California “Shine The Light” Law:</span>{" "}
          California Civil Code Section 1798.83 permits our users who are
          California residents to request and obtain from us, once a year and free
          of charge, information about categories of personal information (if any)
          we disclosed to third parties for direct marketing purposes and the names
          and addresses of all third parties with which we shared personal
          information in the immediately preceding calendar year. If you are a
          California resident and would like to make such a request, please submit
          your request in writing to us by using the contact details provided in
          the section “HOW CAN YOU CONTACT US ABOUT THIS NOTICE?”
        </p>
      </div>

      <ReturnToTop hashUrl={"privacy-content"} category={"Privacy Notice"} />

      {/* 11. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS? */}
      <div className="each-section" id="other-regions-rights">
        <h2 className="heading-h2">
          11. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?
        </h2>
        <p>
          <i>
            <span className="slight-bold">In Short:</span> You may have additional
            rights based on the country you reside in.
          </i>
        </p>
        <p>
          <span className="slight-bold">Australia and New Zealand.</span> We
          collect and process your personal information under the obligations and
          conditions set by Australia’s Privacy Act 1988 and New Zealand’s Privacy
          Act 2020 (Privacy Act). If you do not wish to provide the personal
          information necessary to fulfill its applicable purpose, it may affect
          our ability to provide our services. You have the right to request access
          to or correction of your personal information at any time by contacting
          us using the details below. If you believe we are unlawfully processing
          your personal information, you have the right to submit a complaint
          about a breach of the Australian Privacy Principles to the Office of the
          Australian Information Commissioner and a breach of New Zealand’s
          Privacy Principles to the Office of New Zealand Privacy Commissioner.
        </p>
        <p>
          <span className="slight-bold">Republic of South Africa.</span> At any time,
          you have the right to request access to or correction of your personal
          information by contacting us using the details below. If you are
          unsatisfied with the manner in which we address any complaint regarding
          our processing of personal information, you can contact the Information
          Regulator (South Africa).
        </p>
      </div>

      <ReturnToTop hashUrl={"privacy-content"} category={"Privacy Notice"} />

      {/* 12. ACCOUNT DELETION/DIGITAL ASSET FORFEITURES */}
      <div className="each-section" id="account-deletion">
        <h2 className="heading-h2">12. ACCOUNT DELETION/DIGITAL ASSET FORFEITURES</h2>
        <p>
          By requesting the deletion of your account, you acknowledge and agree to
          the following: If you hold any assets, digital or otherwise, within your
          account at the time of deletion, you forfeit all rights, claims, and
          ownership of those assets. Any such assets will be automatically
          reclaimed by Tycoonland, llc without compensation. Your remaining
          account balance, excluding forfeited assets, will be refunded to your
          default payment method on record, subject to any applicable processing
          fees. Account deletion is irreversible, and Tycoonland, llc will not be
          liable for any loss of access, revenue, or potential future earnings tied
          to the forfeited digital assets. It is your responsibility to liquidate or
          transfer any assets before initiating an account deletion request.
          Failure to do so will result in automatic forfeiture of digitized assets.
        </p>
      </div>

      <ReturnToTop hashUrl={"privacy-content"} category={"Privacy Notice"} />

      {/* 13. DO WE MAKE UPDATES TO THIS NOTICE? */}
      <div className="content_make-updates each-section" id="make-updates">
        <h2 className="heading-h2">13. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
        <p>
          <i>
            <span className="slight-bold">In Short:</span> Yes, we will update
            this notice as necessary to stay compliant with relevant laws.
          </i>
        </p>
        <p>
          We may update this Privacy Notice from time to time. The updated version
          will be indicated by an updated “Revised” date at the top of this Privacy
          Notice. If we make material changes to this Privacy Notice, we may notify
          you either by prominently posting a notice of such changes or by
          directly sending you a notification. We encourage you to review this
          Privacy Notice frequently to be informed of how we are protecting your
          information.
        </p>
      </div>

      <ReturnToTop hashUrl={"privacy-content"} category={"Privacy Notice"} />

      {/* 14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE? */}
      <div className="content_contact-us each-section" id="contact-us">
        <h2 className="heading-h2">14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
        <p>
          If you have questions or comments about this notice, you may contact our
          Data Protection Officer (DPO) by email at support@tycoonland.nyc, by
          phone at 3103409520, or by post at:
        </p>
        <p>
          Tycoonland, llc
          <br />
          Data Protection Officer
          <br />
          1309 Coffeen Ave ste 1200
          <br />
          Sheridan, WY 82801
          <br />
          United States
        </p>
      </div>

      <ReturnToTop hashUrl={"privacy-content"} category={"Privacy Notice"} />

      {/* 15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU? */}
      <div className="content_data-you each-section" id="data-you">
        <h2 className="heading-h2">
          15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
        </h2>
        <p>
          You have the right to request access to the personal information we
          collect from you, details about how we have processed it, correct
          inaccuracies, or delete your personal information. You may also have the
          right to withdraw your consent to our processing of your personal
          information. These rights may be limited in some circumstances by
          applicable law. To request to review, update, or delete your personal
          information, please fill out and submit a data subject access request or
          contact us at support@tycoonland.nyc.
        </p>
      </div>
    </div>
  );
}

export default PrivacyNotice;
