import privacyQuestions from "./allQuestions";
import termsTopics from "./termsTable";
import disclaimerTopics from "./disclaimer";
import EULATopics from "./eula";
const pageLinks = [
  {
    name: "Privacy Notice",
    id: "privacy",
    url: "/privacy",
    sub: privacyQuestions,
  },
  {
    name: "Terms of Services",
    id: "terms",
    url: "/terms",
    sub: termsTopics,
  },
  {
    name: "End User License Agreement",
    id: "EULA",
    url: "/EULA",
    sub: EULATopics,
  },
  {
    name: "Disclaimer",
    id: "disclaimer",
    url: "/disclaimer",
    sub: disclaimerTopics,
  },
];


export default pageLinks;
