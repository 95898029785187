import React from "react";
import ReturnToTop from "../ReturnToTop/ReturnToTop";
import "./Disclaimer.css";

function Disclaimer() {
  return (
    <div className="dis" id="dis1">
      {/* Heading */}
      <div className="dis-heading">
        <h2 className="dis_heading-h2 heading-h2">DISCLAIMER</h2>
        {/* Updated to match the PDF date */}
        <p className="dis_heading-time">Last updated March 07, 2025</p>
      </div>

      {/* Website Disclaimer */}
      <div className="each-sec">
        <h2 className="sec-heading heading-h2">Website Disclaimer</h2>
        <p>
          The information provided by Tycoonland, llc ("we," "us," or "our") on{" "}
          <a
            className="content-link"
            href="https://www.tycoonland.nyc"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.tycoonland.nyc
          </a>{" "}
          (the "Site") and our mobile application is for general informational
          purposes only. All information on the Site and our mobile application is
          provided in good faith; however, we make no representation or warranty of
          any kind, express or implied, regarding the accuracy, adequacy, validity,
          reliability, availability, or completeness of any information on the Site
          or our mobile application.
        </p>
        <p>
          UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR
          DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR OUR MOBILE
          APPLICATION OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE AND OUR
          MOBILE APPLICATION. YOUR USE OF THE SITE AND OUR MOBILE APPLICATION AND YOUR
          RELIANCE ON ANY INFORMATION ON THE SITE AND OUR MOBILE APPLICATION IS SOLELY
          AT YOUR OWN RISK.
        </p>
      </div>

      <ReturnToTop hashUrl={"dis1"} category={"Disclaimer"} />

      {/* External Links Disclaimer */}
      <div className="each-sec">
        <h2 className="sec-heading heading-h2">External Links Disclaimer</h2>
        <p>
          The Site and our mobile application may contain (or you may be sent through the
          Site or our mobile application) links to other websites or content belonging to
          or originating from third parties, or links to websites and features in banners or
          other advertising. Such external links are not investigated, monitored, or checked
          for accuracy, adequacy, validity, reliability, availability, or completeness by
          us.
        </p>
        <p>
          WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE ACCURACY
          OR RELIABILITY OF ANY INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE
          SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT
          BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU
          AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
        </p>
      </div>

      <ReturnToTop hashUrl={"dis1"} category={"Disclaimer"} />

      {/* Affiliates Disclaimer */}
      <div className="each-sec">
        <h2 className="sec-heading heading-h2">Affiliates Disclaimer</h2>
        <p>
          The Site and our mobile application may contain links to affiliate websites, and
          we receive an affiliate commission for any purchases made by you on the affiliate
          website using such links. Our affiliates include the following:
        </p>
        {/* Added questgrid.com as per the PDF */}
        <ul>
          <li>
            <a
              className="content-link"
              href="https://questgrid.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://questgrid.com
            </a>
          </li>
        </ul>
      </div>

      <ReturnToTop hashUrl={"dis1"} category={"Disclaimer"} />
    </div>
  );
}

export default Disclaimer;
