import React from "react";
import ReturnToTop from "../ReturnToTop/ReturnToTop";
import "./EULA.css";

function EULA() {
  return (
    <div className="eula" id="eula-1">
      {/* Heading */}
      <div className="eula-heading">
        <h2 className="eula_heading-h2 heading-h2">END USER LICENSE AGREEMENT</h2>
        <p className="eula_heading-time">Last updated March 07, 2025</p>
      </div>

      {/* Intro */}
      <div className="each-sec">
        <p>
          Tycoonland is licensed to You (End-User) by Tycoonland, llc, located and registered at
          1309 Coffeen Ave ste 1200, Sheridan, WY 82801, USA, Sheridan, 82801, United States
          ("Licensor"), for use only under the terms of this License Agreement.
        </p>
        <p>
          By downloading the Licensed Application from Apple's software distribution platform
          ("App Store") and Google's software distribution platform ("Play Store"), and any
          update thereto (as permitted by this License Agreement), You indicate that You agree
          to be bound by all of the terms and conditions of this License Agreement, and that You
          accept this License Agreement. App Store and Play Store are referred to in this License
          Agreement as "Services."
        </p>
        <p>
          The parties of this License Agreement acknowledge that the Services are not a Party to
          this License Agreement and are not bound by any provisions or obligations with regard
          to the Licensed Application, such as warranty, liability, maintenance and support thereof.
          Tycoonland, llc, not the Services, is solely responsible for the Licensed Application
          and the content thereof.
        </p>
        <p>
          This License Agreement may not provide for usage rules for the Licensed Application
          that are in conflict with the latest Apple Media Services Terms and Conditions and
          Google Play Terms of Service ("Usage Rules"). Tycoonland, llc acknowledges that it
          had the opportunity to review the Usage Rules and this License Agreement is not
          conflicting with them.
        </p>
        <p>
          Tycoonland when purchased or downloaded through the Services, is licensed to You for
          use only under the terms of this License Agreement. The Licensor reserves all rights not
          expressly granted to You. Tycoonland is to be used on devices that operate with Apple's
          operating systems ("iOS" and "Mac OS") or Google's operating system ("Android").
        </p>
      </div>

      <ReturnToTop hashUrl="eula-1" category="EULA" />

      {/* Table of Contents */}
      <div className="each-sec">
        <h2 className="sec-heading heading-h2">TABLE OF CONTENTS</h2>
        <ol>
          <li>THE APPLICATION</li>
          <li>SCOPE OF LICENSE</li>
          <li>TECHNICAL REQUIREMENTS</li>
          <li>MAINTENANCE AND SUPPORT</li>
          <li>USE OF DATA</li>
          <li>USER-GENERATED CONTRIBUTIONS</li>
          <li>CONTRIBUTION LICENSE</li>
          <li>LIABILITY</li>
          <li>WARRANTY</li>
          <li>PRODUCT CLAIMS</li>
          <li>LEGAL COMPLIANCE</li>
          <li>CONTACT INFORMATION</li>
          <li>TERMINATION</li>
          <li>THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY</li>
          <li>INTELLECTUAL PROPERTY RIGHTS</li>
          <li>APPLICABLE LAW</li>
          <li>MISCELLANEOUS</li>
        </ol>
      </div>

      <ReturnToTop hashUrl="eula-1" category="EULA" />

      {/* 1. THE APPLICATION */}
      <div className="each-sec" id="app">
        <h2 className="sec-heading heading-h2">1. THE APPLICATION</h2>
        <p>
          Tycoonland ("Licensed Application") is a piece of software created to allow players to
          immerse themselves into the fast-paced world of New York City's real estate market.
          The Game offers a realistic portrayal of the challenges and opportunities faced by
          real estate investors in the financial capital of the world. Players must make
          strategic decisions to maximize profits, negotiate deals with savvy competitors, and
          manage their investments wisely to build a lucrative portfolio. Featuring detailed
          graphics and dynamic gameplay, Tycoonland captures the essence of Manhattan's
          skyline, from historic brownstones to gleaming skyscrapers. Whether buying up
          luxury condos in Tribeca or revitalizing properties in Harlem, players can experience
          the thrill of real estate entrepreneurship from the comfort of their own mobile
          devices. With multiplayer options, players can also compete against friends or
          collaborate to dominate the market together. Tycoonland is a must-play game for
          anyone fascinated by keen strategies of finance, investment, and competition in
          America's most vibrant cities — and customized for iOS and Android mobile devices
          ("Devices"). It is used to take on the role of budding real estate tycoons, navigating
          the complexities of buying, selling, and developing properties across Manhattan's
          iconic neighborhoods.
        </p>
      </div>

      <ReturnToTop hashUrl="eula-1" category="EULA" />

      {/* 2. SCOPE OF LICENSE */}
      <div className="each-sec" id="scope">
        <h2 className="sec-heading heading-h2">2. SCOPE OF LICENSE</h2>
        <p>
          2.1 You are given a non-transferable, non-exclusive, non-sublicensable license to
          install and use the Licensed Application on any Devices that You (End-User) own or
          control and as permitted by the Usage Rules, with the exception that such Licensed
          Application may be accessed and used by other accounts associated with You (End-User,
          The Purchaser) via Family Sharing or volume purchasing.
        </p>
        <p>
          2.2 This license will also govern any updates of the Licensed Application provided
          by Licensor that replace, repair, and/or supplement the first Licensed Application,
          unless a separate license is provided for such update, in which case the terms of that
          new license will govern.
        </p>
        <p>
          2.3 You may not share or make the Licensed Application available to third parties
          (unless to the degree allowed by the Usage Rules, and with Tycoonland, llc's prior
          written consent), sell, rent, lend, lease or otherwise redistribute the Licensed
          Application.
        </p>
        <p>
          2.4 You may not reverse engineer, translate, disassemble, integrate, decompile,
          remove, modify, combine, create derivative works or updates of, adapt, or attempt to
          derive the source code of the Licensed Application, or any part thereof (except with
          Tycoonland, llc's prior written consent).
        </p>
        <p>
          2.5 You may not copy (excluding when expressly authorized by this license and the
          Usage Rules) or alter the Licensed Application or portions thereof. You may create
          and store copies only on devices that You own or control for backup keeping under
          the terms of this license, the Usage Rules, and any other terms and conditions that
          apply to the device or software used. You may not remove any intellectual property
          notices. You acknowledge that no unauthorized third parties may gain access to these
          copies at any time. If you sell your Devices to a third party, you must remove the
          Licensed Application from the Devices before doing so.
        </p>
        <p>
          2.6 Violations of the obligations mentioned above, as well as the attempt of such
          infringement, may be subject to prosecution and damages.
        </p>
        <p>
          2.7 Licensor reserves the right to modify the terms and conditions of licensing.
        </p>
        <p>
          2.8 Nothing in this license should be interpreted to restrict third-party terms. When
          using the Licensed Application, You must ensure that You comply with applicable
          third-party terms and conditions.
        </p>
      </div>

      <ReturnToTop hashUrl="eula-1" category="EULA" />

      {/* 3. TECHNICAL REQUIREMENTS */}
      <div className="each-sec" id="tech-req">
        <h2 className="sec-heading heading-h2">3. TECHNICAL REQUIREMENTS</h2>
        <p>
          3.1 The Licensed Application requires a firmware version 1.0.0 or higher. Licensor
          recommends using the latest version of the firmware.
        </p>
        <p>
          3.2 Licensor attempts to keep the Licensed Application updated so that it complies
          with modified/new versions of the firmware and new hardware. You are not granted
          rights to claim such an update.
        </p>
        <p>
          3.3 You acknowledge that it is Your responsibility to confirm and determine that the
          app end-user device on which You intend to use the Licensed Application satisfies
          the technical specifications mentioned above.
        </p>
        <p>
          3.4 Licensor reserves the right to modify the technical specifications as it sees
          appropriate at any time.
        </p>
      </div>

      <ReturnToTop hashUrl="eula-1" category="EULA" />

      {/* 4. MAINTENANCE AND SUPPORT */}
      <div className="each-sec" id="support">
        <h2 className="sec-heading heading-h2">4. MAINTENANCE AND SUPPORT</h2>
        <p>
          4.1 The Licensor is solely responsible for providing any maintenance and support
          services for this Licensed Application. You can reach the Licensor at the email
          address listed in the App Store or Play Store Overview for this Licensed Application.
        </p>
        <p>
          4.2 Tycoonland, llc and the End-User acknowledge that the Services have no obligation
          whatsoever to furnish any maintenance and support services with respect to the Licensed
          Application.
        </p>
      </div>

      <ReturnToTop hashUrl="eula-1" category="EULA" />

      {/* 5. USE OF DATA */}
      <div className="each-sec" id="use-of-data">
        <h2 className="sec-heading heading-h2">5. USE OF DATA</h2>
        <p>
          You acknowledge that Licensor will be able to access and adjust Your downloaded
          Licensed Application content and Your personal information, and that Licensor's use
          of such material and information is subject to Your legal agreements with Licensor and
          Licensor's privacy policy, which can be accessed by clicking on our listed legal terms.
        </p>
        <p>
          You acknowledge that the Licensor may periodically collect and use technical data and
          related information about your device, system, and application software, and peripherals,
          offer product support, facilitate the software updates, and for purposes of providing
          other services to you (if any) related to the Licensed Application. Licensor may also
          use this information to improve its products or to provide services or technologies to
          you, as long as it is in a form that does not personally identify you.
        </p>
      </div>

      <ReturnToTop hashUrl="eula-1" category="EULA" />

      {/* 6. USER-GENERATED CONTRIBUTIONS */}
      <div className="each-sec" id="user-generated">
        <h2 className="sec-heading heading-h2">6. USER-GENERATED CONTRIBUTIONS</h2>
        <p>
          The Licensed Application does not offer users to submit or post content. We may
          provide you with the opportunity to create, submit, post, display, transmit, perform,
          publish, distribute, or broadcast content and materials to us or in the Licensed
          Application, including but not limited to text, writings, video, audio, photographs,
          graphics, comments, suggestions, or personal information or other material
          (collectively, "Contributions"). Contributions may be viewable by other users of the
          Licensed Application and through third-party websites or applications. As such, any
          Contributions you transmit may be treated in accordance with the Licensed Application
          Privacy Policy.
        </p>
        <p>
          When you create or make available any Contributions, you thereby represent and warrant
          that:
        </p>
        <ol>
          <li>
            The creation, distribution, transmission, public display, or performance, and the
            accessing, downloading, or copying of your Contributions do not and will not infringe
            the proprietary rights (including but not limited to copyright, patent, trademark,
            trade secret, or moral rights) of any third party.
          </li>
          <li>
            You are the creator and owner of or have the necessary licenses, rights, consents,
            releases, and permissions to use and authorize us, the Licensed Application, and other
            users of the Licensed Application to use your Contributions in any manner contemplated
            by the Licensed Application and this License Agreement.
          </li>
          <li>
            You have the written consent, release, and/or permission of each and every identifiable
            individual person in your Contributions to use the name or likeness of each such
            individual person to enable inclusion and use of your Contributions in any manner
            contemplated by the Licensed Application and this License Agreement.
          </li>
          <li>Your Contributions are not false, inaccurate, or misleading.</li>
          <li>
            Your Contributions are not unsolicited or unauthorized advertising, promotional
            materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of
            solicitation.
          </li>
          <li>
            Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing,
            libelous, slanderous, or otherwise objectionable (as determined by us).
          </li>
          <li>Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.</li>
          <li>
            Your Contributions are not used to harass or threaten (in the legal sense of those
            terms) any other person and to promote violence against a specific person or class of
            people.
          </li>
          <li>Your Contributions do not violate any applicable law, regulation, or rule.</li>
          <li>
            Your Contributions do not violate the privacy or publicity rights of any third party.
          </li>
          <li>
            Your Contributions do not violate any applicable law concerning child pornography or
            otherwise intended to protect the health or well-being of minors.
          </li>
          <li>
            Your Contributions do not include any offensive comments connected to race, national
            origin, gender, sexual preference, or physical handicap.
          </li>
          <li>
            Your Contributions do not otherwise violate, or link to material that violates, any
            provision of this License Agreement, or any applicable law or regulation.
          </li>
        </ol>
        <p>
          Any use of the Licensed Application in violation of the foregoing violates this License
          Agreement and may result in, among other things, termination or suspension of your
          rights to use the Licensed Application.
        </p>
      </div>

      <ReturnToTop hashUrl="eula-1" category="EULA" />

      {/* 7. CONTRIBUTION LICENSE */}
      <div className="each-sec" id="contribution-license">
        <h2 className="sec-heading heading-h2">7. CONTRIBUTION LICENSE</h2>
        <p>
          You agree that we may access, store, process, and use any information and personal data
          that you provide following the terms of the Privacy Policy and your choices (including
          settings).
        </p>
        <p>
          By submitting suggestions or other feedback regarding the Licensed Application, you agree
          that we can use and share such feedback for any purpose without compensation to you.
        </p>
        <p>
          We do not assert any ownership over your Contributions. You retain full ownership of
          all of your Contributions and any intellectual property rights or other proprietary
          rights associated with your Contributions. We are not liable for any statements or
          representations in your Contributions provided by you in any area in the Licensed
          Application. You are solely responsible for your Contributions to the Licensed
          Application and you expressly agree to exonerate us from any and all responsibility and
          to refrain from any legal action against us regarding your Contributions.
        </p>
      </div>

      <ReturnToTop hashUrl="eula-1" category="EULA" />

      {/* 8. LIABILITY */}
      <div className="each-sec" id="liability">
        <h2 className="sec-heading heading-h2">8. LIABILITY</h2>
        <p>
          8.1 Licensor's responsibility in the case of violation of obligations and tort shall be
          limited to intent and gross negligence. Only in case of a breach of essential contractual
          duties (cardinal obligations), Licensor shall also be liable in case of slight negligence.
          In any case, liability shall be limited to the foreseeable, contractually typical damages.
          The limitation mentioned above does not apply to injuries to life, limb, or health.
        </p>
        <p>
          8.2 Licensor takes no accountability or responsibility for any damages caused due to a
          breach of duties according to Section 2 of this License Agreement. To avoid data loss,
          You are required to make use of backup functions of the Licensed Application to the
          extent allowed by applicable third-party terms and conditions of use. You are aware that
          in case of alterations or manipulations of the Licensed Application, You will not have
          access to the Licensed Application.
        </p>
        <p>
          8.3 Licensor takes no accountability and responsibility in case of "Account deletion."
        </p>
      </div>

      <ReturnToTop hashUrl="eula-1" category="EULA" />

      {/* 9. WARRANTY */}
      <div className="each-sec" id="warranty">
        <h2 className="sec-heading heading-h2">9. WARRANTY</h2>
        <p>
          9.1 Licensor warrants that the Licensed Application is free of spyware, trojan horses,
          viruses, or any other malware at the time of Your download. Licensor warrants that
          the Licensed Application works as described in the user documentation.
        </p>
        <p>
          9.2 No warranty is provided for the Licensed Application that is not executable on the
          device, that has been unauthorizedly modified, handled inappropriately or culpably,
          combined or installed with inappropriate hardware or software, used with
          inappropriate accessories, regardless if by Yourself or by third parties, or if there
          are any other reasons outside of Tycoonland, llc's sphere of influence that affect the
          executability of the Licensed Application.
        </p>
        <p>
          9.3 You are required to inspect the Licensed Application immediately after installing
          it and notify Tycoonland, llc about issues discovered without delay by email provided
          in Contact Information. The defect report will be taken into consideration and further
          investigated if it has been emailed within a period of three (3) days after discovery.
        </p>
        <p>
          9.4 If we confirm that the Licensed Application is defective, Tycoonland, llc reserves
          a choice to remedy the situation either by means of solving the defect or substitute
          delivery.
        </p>
        <p>
          9.5 In the event of any failure of the Licensed Application to conform to any applicable
          warranty, You may notify the Services Store Operator, and Your Licensed Application
          purchase price will be refunded to You. To the maximum extent permitted by applicable
          law, the Services Store Operator will have no other warranty obligation whatsoever
          with respect to the Licensed Application, and any other losses, claims, damages,
          liabilities, expenses, and costs attributable to any negligence to adhere to any warranty.
        </p>
        <p>
          9.6 If the user is an entrepreneur, any claim based on faults expires after a statutory
          period of limitation amounting to twelve (12) months after the Licensed Application
          was made available to the user. The statutory periods of limitation given by law apply
          for users who are consumers.
        </p>
      </div>

      <ReturnToTop hashUrl="eula-1" category="EULA" />

      {/* 10. PRODUCT CLAIMS */}
      <div className="each-sec" id="product-claims">
        <h2 className="sec-heading heading-h2">10. PRODUCT CLAIMS</h2>
        <p>
          Tycoonland, llc and the End-User acknowledge that Tycoonland, llc, and not the
          Services, is responsible for addressing any claims of the End-User or any third party
          relating to the Licensed Application or the End-User’s possession and/or use of that
          Licensed Application, including, but not limited to:
        </p>
        <ul>
          <li>(i) product liability claims;</li>
          <li>
            (ii) any claim that the Licensed Application fails to conform to any applicable
            legal or regulatory requirement; and
          </li>
          <li>
            (iii) claims arising under consumer protection, privacy, or similar legislation,
            including in connection with Your Licensed Application’s use of the HealthKit
            and HomeKit.
          </li>
        </ul>
      </div>

      <ReturnToTop hashUrl="eula-1" category="EULA" />

      {/* 11. LEGAL COMPLIANCE */}
      <div className="each-sec" id="legal-compliance">
        <h2 className="sec-heading heading-h2">11. LEGAL COMPLIANCE</h2>
        <p>
          You represent and warrant that You are not located in a country that is subject to a US
          Government embargo, or that has been designated by the US Government as a "terrorist
          supporting" country; and that You are not listed on any US Government list of
          prohibited or restricted parties.
        </p>
      </div>

      <ReturnToTop hashUrl="eula-1" category="EULA" />

      {/* 12. CONTACT INFORMATION */}
      <div className="each-sec" id="contact-info">
        <h2 className="sec-heading heading-h2">12. CONTACT INFORMATION</h2>
        <p>For general inquiries, complaints, questions or claims concerning the Licensed Application, please contact:</p>
        <p>
          John Michael Smith
          <br />
          1309 Coffeen Ave ste 1200, Sheridan, WY 82801, USA
          <br />
          Sheridan, 82801
          <br />
          United States
          <br />
          <a className="content-link" href="mailto:support@tycoonland.nyc">
            support@tycoonland.nyc
          </a>
        </p>
      </div>

      <ReturnToTop hashUrl="eula-1" category="EULA" />

      {/* 13. TERMINATION */}
      <div className="each-sec" id="termination">
        <h2 className="sec-heading heading-h2">13. TERMINATION</h2>
        <p>
          The license is valid until terminated by Tycoonland, llc or by You. Your rights under
          this license will terminate automatically and without notice from Tycoonland, llc if You
          fail to adhere to any term(s) of this license. Upon License termination, You shall stop
          all use of the Licensed Application, and destroy all copies, full or partial, of the
          Licensed Application.
        </p>
      </div>

      <ReturnToTop hashUrl="eula-1" category="EULA" />

      {/* 14. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY */}
      <div className="each-sec" id="third-party">
        <h2 className="sec-heading heading-h2">
          14. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY
        </h2>
        <p>
          Tycoonland, llc represents and warrants that Tycoonland, llc will comply with applicable
          third-party terms of agreement when using Licensed Application.
        </p>
        <p>
          In Accordance with Section 9 of the "Instructions for Minimum Terms of Developer's
          End-User License Agreement," both Apple and Google and their subsidiaries shall be
          third-party beneficiaries of this End User License Agreement and — upon Your
          acceptance of the terms and conditions of this License Agreement, both Apple and
          Google will have the right (and will be deemed to have accepted the right) to enforce
          this End User License Agreement against You as a third-party beneficiary thereof.
        </p>
      </div>

      <ReturnToTop hashUrl="eula-1" category="EULA" />

      {/* 15. INTELLECTUAL PROPERTY RIGHTS */}
      <div className="each-sec" id="ipr">
        <h2 className="sec-heading heading-h2">15. INTELLECTUAL PROPERTY RIGHTS</h2>
        <p>
          Tycoonland, llc and the End-User acknowledge that, in the event of any third-party
          claim that the Licensed Application or the End-User's possession and use of that
          Licensed Application infringes on the third party's intellectual property rights,
          Tycoonland, llc, and not the Services, will be solely responsible for the
          investigation, defense, settlement, and discharge of any such intellectual property
          infringement claims.
        </p>
      </div>

      <ReturnToTop hashUrl="eula-1" category="EULA" />

      {/* 16. APPLICABLE LAW */}
      <div className="each-sec" id="applicable-law">
        <h2 className="sec-heading heading-h2">16. APPLICABLE LAW</h2>
        <p>
          This License Agreement is governed by the laws of the State of Wyoming excluding its
          conflicts of law rules.
        </p>
      </div>

      <ReturnToTop hashUrl="eula-1" category="EULA" />

      {/* 17. MISCELLANEOUS */}
      <div className="each-sec" id="misc">
        <h2 className="sec-heading heading-h2">17. MISCELLANEOUS</h2>
        <p>
          17.1 If any of the terms of this agreement should be or become invalid, the validity of
          the remaining provisions shall not be affected. Invalid terms will be replaced by valid
          ones formulated in a way that will achieve the primary purpose.
        </p>
        <p>
          17.2 Collateral agreements, changes, and amendments are only valid if laid down in
          writing. The preceding clause can only be waived in writing.
        </p>
        <p>
          17.3 <strong>Account Deletion and Digital Asset Forfeitures:</strong> By requesting the
          deletion of your account, you acknowledge and agree to the following: If you hold any
          assets, digital or otherwise, within your account at the time of deletion, you forfeit all
          rights, claims, and ownership of those assets. Any such assets will be automatically
          reclaimed by Tycoonland, llc without compensation. Your remaining account balance,
          excluding forfeited assets, will be refunded to your default payment method on record,
          subject to any applicable processing fees. Account deletion is irreversible, and
          Tycoonland, llc will not be liable for any loss of access, revenue, or potential future
          earnings tied to the forfeited digital assets. It is your responsibility to liquidate or
          transfer any assets before initiating an account deletion request. Failure to do so will
          result in automatic forfeiture of digitized assets.
        </p>
      </div>

      <ReturnToTop hashUrl="eula-1" category="EULA" />
    </div>
  );
}

export default EULA;
