import React from "react";
import ReturnToTop from "../ReturnToTop/ReturnToTop";
import "./TermsOfServices.css";

function TermsOfUseFull() {
  return (
    <div className="terms-full" id="term1">
      {/* Heading */}
      <div className="terms-heading">
        <h2 className="terms_heading-h2 heading-h2">TERMS OF SERVICE</h2>
        <p className="terms_heading-time">Last updated March 07, 2025</p>
      </div>

      {/* 1. Agreement to Our Legal Terms */}
      <div className="each-sec" id="sec-1">
        <h2 className="sec-heading heading-h2">1. AGREEMENT TO OUR LEGAL TERMS</h2>
        <p>
          We are Tycoonland, llc ("Company," "we," "us," "our"), a company registered
          in Wyoming, United States at 1309 Coffeen Ave ste 1200, Sheridan, WY 82801,
          USA, Sheridan, WY 82801.
        </p>
        <p>
          We operate the website{" "}
          <a
            className="content-link"
            href="https://www.tycoonland.nyc"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.tycoonland.nyc
          </a>{" "}
          (the "Site"), the mobile application Tycoonland (the "App"), as well as any other
          related products and services that refer or link to these legal terms (the "Legal
          Terms") (collectively, the "Services").
        </p>
        <p>
          <strong>About the Tycoonland Game:</strong> Tycoonland is an innovative
          strategy-based game that allows players to immerse themselves into the
          fast-paced world of New York City's real estate market. Players take on
          the role of budding real estate tycoons, navigating the complexities of buying,
          selling, and developing properties across Manhattan's iconic neighborhoods.
          The Game offers a realistic portrayal of the challenges and opportunities faced
          by real estate investors in the financial capital of the world. Players must make
          strategic decisions to maximize profits, negotiate deals with savvy competitors,
          and manage their investments wisely to build a lucrative portfolio. Featuring
          detailed graphics and dynamic gameplay, Tycoonland captures the essence of
          Manhattan's skyline, from historic brownstones to gleaming skyscrapers. Whether
          buying up luxury condos in Tribeca or revitalizing properties in Harlem, players
          can experience the thrill of real estate entrepreneurship from the comfort of
          their own mobile devices. With multiplayer options, players can also compete
          against friends or collaborate to dominate the market together.
        </p>
        <p>
          You can contact us by phone at (+1)3103409520, email at{" "}
          <a className="content-link" href="mailto:support@tycoonland.nyc">
            support@tycoonland.nyc
          </a>
          , or by mail to 1309 Coffeen Ave ste 1200, Sheridan, WY 82801, USA, Sheridan,
          WY 82801, United States.
        </p>
        <p>
          These Legal Terms constitute a legally binding agreement made between you,
          whether personally or on behalf of an entity ("you"), and Tycoonland, llc,
          concerning your access to and use of the Services. You agree that by accessing
          the Services, you have read, understood, and agreed to be bound by all of these
          Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN
          YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST
          DISCONTINUE USE IMMEDIATELY.
        </p>
        <p>
          We will provide you with prior notice of any scheduled changes to the Services
          you are using. The modified Legal Terms will become effective upon posting or
          notifying you by support@tycoonland.nyc, as stated in the email message.
          By continuing to use the Services after the effective date of any changes, you
          agree to be bound by the modified terms.
        </p>
        <p>
          All users who are minors in the jurisdiction in which they reside (generally under
          the age of 18) must have the permission of, and be directly supervised by, their
          parent or guardian to use the Services. If you are a minor, you must have your
          parent or guardian read and agree to these Legal Terms prior to you using the
          Services.
        </p>
        <p>
          We recommend that you print a copy of these Legal Terms for your records.
        </p>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* Table of Contents */}
      <div className="each-sec" id="sec-toc">
        <h2 className="sec-heading heading-h2">TABLE OF CONTENTS</h2>
        <ol>
          <li>Agreement to Our Legal Terms</li>
          <li>Our Services</li>
          <li>Intellectual Property Rights</li>
          <li>User Representations</li>
          <li>User Registration</li>
          <li>Products</li>
          <li>Purchases and Payment</li>
          <li>Refunds Policy</li>
          <li>Software</li>
          <li>Prohibited Activities</li>
          <li>User Generated Contributions</li>
          <li>Contribution License</li>
          <li>Guidelines for Reviews</li>
          <li>Mobile Application License</li>
          <li>Services Management</li>
          <li>Privacy Policy</li>
          <li>Term and Termination</li>
          <li>Modifications and Interruptions</li>
          <li>Governing Law</li>
          <li>Dispute Resolution</li>
          <li>Corrections</li>
          <li>Disclaimer</li>
          <li>Limitations of Liability</li>
          <li>Indemnification</li>
          <li>User Data</li>
          <li>Electronic Communications, Transactions, and Signatures</li>
          <li>SMS Text Messaging</li>
          <li>California Users and Residents</li>
          <li>Miscellaneous</li>
          <li>Account Deletion and Digital Asset Forfeitures</li>
          <li>Contact Us</li>
        </ol>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* 2. Our Services */}
      <div className="each-sec" id="sec-2">
        <h2 className="sec-heading heading-h2">2. OUR SERVICES</h2>
        <p>
          The information provided when using the Services is not intended for distribution
          to or use by any person or entity in any jurisdiction or country where such
          distribution or use would be contrary to law or regulation or which would subject
          us to any registration requirement within such jurisdiction or country. Accordingly,
          those persons who choose to access the Services from other locations do so on
          their own initiative and are solely responsible for compliance with local laws, if and
          to the extent local laws are applicable.
        </p>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* 3. Intellectual Property Rights */}
      <div className="each-sec" id="sec-3">
        <h2 className="sec-heading heading-h2">3. INTELLECTUAL PROPERTY RIGHTS</h2>
        <p>
          We are the owner or the licensee of all intellectual property rights in our Services,
          including all source code, databases, functionality, software, website designs,
          audio, video, text, photographs, and graphics in the Services (collectively, the
          "Content"), as well as the trademarks, service marks, and logos contained therein
          (the "Marks"). Our Content and Marks are protected by copyright and trademark
          laws (and various other intellectual property rights and unfair competition laws)
          and treaties in the United States and around the world.
        </p>
        <p>
          The Content and Marks are provided in or through the Services "AS IS" for your
          personal, non-commercial use or internal business purpose only. Subject to your
          compliance with these Legal Terms, we grant you a non-exclusive, non-transferable,
          revocable license to:
        </p>
        <ul>
          <li>Access the Services; and</li>
          <li>Download or print a copy of any portion of the Content to which you have properly gained access.</li>
        </ul>
        <p>
          Except as set out in this section or elsewhere in our Legal Terms, no part of the
          Services and no Content or Marks may be copied, reproduced, aggregated,
          republished, uploaded, posted, publicly displayed, encoded, translated, transmitted,
          distributed, sold, licensed, or otherwise exploited for any commercial purpose
          whatsoever, without our express prior written permission.
        </p>
        <p>
          Any breach of these Intellectual Property Rights will constitute a material breach
          of our Legal Terms and your right to use our Services will terminate immediately.
        </p>
        <p>
          <strong>Your Submissions:</strong> By directly sending us any question, comment,
          suggestion, idea, feedback, or other information about the Services ("Submissions"),
          you agree to assign to us all intellectual property rights in such Submission. You
          agree that we shall own this Submission and be entitled to its unrestricted use and
          dissemination for any lawful purpose, commercial or otherwise, without
          acknowledgment or compensation to you.
        </p>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* 4. User Representations */}
      <div className="each-sec" id="sec-4">
        <h2 className="sec-heading heading-h2">4. USER REPRESENTATIONS</h2>
        <p>
          By using the Services, you represent and warrant that: (1) all registration
          information you submit will be true, accurate, current, and complete; (2) you will
          maintain the accuracy of such information and promptly update such information as
          necessary; (3) you have the legal capacity and agree to comply with these Legal
          Terms; (4) you are not a minor in the jurisdiction in which you reside, or if a minor,
          you have received parental permission to use the Services; (5) you will not access the
          Services through automated or non-human means; (6) you will not use the Services
          for any illegal or unauthorized purpose; and (7) your use of the Services will not
          violate any applicable law or regulation.
        </p>
        <p>
          If you provide any information that is untrue, inaccurate, not current, or incomplete,
          we have the right to suspend or terminate your account and refuse any and all
          current or future use of the Services.
        </p>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* 5. User Registration */}
      <div className="each-sec" id="sec-5">
        <h2 className="sec-heading heading-h2">5. USER REGISTRATION</h2>
        <p>
          You may be required to register to use the Services. You agree to keep your password
          confidential and will be responsible for all use of your account. We reserve the right
          to remove, reclaim, or change a username if we determine, in our sole discretion,
          that it is inappropriate, obscene, or otherwise objectionable.
        </p>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* 6. Products */}
      <div className="each-sec" id="sec-6">
        <h2 className="sec-heading heading-h2">6. PRODUCTS</h2>
        <p>
          All products are subject to availability. We reserve the right to discontinue any
          products at any time for any reason. Prices for all products are subject to change.
        </p>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* 7. Purchases and Payment */}
      <div className="each-sec" id="sec-7">
        <h2 className="sec-heading heading-h2">7. PURCHASES AND PAYMENT</h2>
        <p>We accept the following forms of payment:</p>
        <ul>
          <li>Visa</li>
          <li>Mastercard</li>
          <li>American Express</li>
          <li>Discover</li>
          <li>PayPal</li>
        </ul>
        <p>
          You agree to provide current, complete, and accurate purchase and account
          information for all purchases made via the Services, and to promptly update such
          information. Sales tax will be added as required, and all payments shall be in
          US dollars.
        </p>
        <p>
          You agree to pay all charges at the prices then in effect for your purchases and any
          applicable shipping fees, and you authorize us to charge your chosen payment
          provider upon order placement. We reserve the right to correct any pricing errors
          even after payment.
        </p>
        <p>
          We reserve the right to refuse any order placed through the Services and may limit
          or cancel quantities per person, household, or order at our sole discretion.
        </p>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* 8. Refunds Policy */}
      <div className="each-sec" id="sec-8">
        <h2 className="sec-heading heading-h2">8. REFUNDS POLICY</h2>
        <p>All sales are final and no refund will be issued.</p>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* 9. Software */}
      <div className="each-sec" id="sec-9">
        <h2 className="sec-heading heading-h2">9. SOFTWARE</h2>
        <p>
          We may include software for use in connection with our Services. If such software
          is accompanied by an end user license agreement ("EULA"), the terms of that EULA
          will govern your use of the software. If not, we grant you a non-exclusive,
          revocable, personal, and non-transferable license to use such software solely in
          connection with our Services and in accordance with these Legal Terms. All
          software and related documentation is provided "AS IS" without warranty of
          any kind.
        </p>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* 10. Prohibited Activities */}
      <div className="each-sec" id="sec-10">
        <h2 className="sec-heading heading-h2">10. PROHIBITED ACTIVITIES</h2>
        <p>
          You may not access or use the Services for any purpose other than that for which
          they are provided. You agree not to:
        </p>
        <ul>
          <li>Systematically retrieve data or content to create a database or directory without permission;</li>
          <li>Trick, defraud, or mislead us or other users;</li>
          <li>Circumvent or interfere with security-related features of the Services;</li>
          <li>Disparage or harm us or the Services;</li>
          <li>Use information obtained from the Services to harass or harm others;</li>
          <li>Make improper use of our support services;</li>
          <li>Use the Services in violation of any applicable law or regulation;</li>
          <li>Engage in unauthorized framing or linking to the Services;</li>
          <li>Upload or transmit viruses, Trojan horses, or other harmful material;</li>
          <li>Use automated systems (e.g., bots or scripts) to access the Services;</li>
          <li>Delete copyright or proprietary notices from any content;</li>
          <li>Attempt to impersonate another user;</li>
          <li>Upload any material that acts as a data collection mechanism (e.g., cookies);</li>
          <li>Interfere with the Services or the networks connected to them;</li>
          <li>Harass or threaten our employees or agents;</li>
          <li>Bypass measures designed to prevent access to the Services;</li>
          <li>Copy or adapt the Services' software;</li>
          <li>Reverse engineer or decompile any part of the Services (except as permitted by law);</li>
          <li>Use a buying or purchasing agent to make purchases;</li>
          <li>Collect usernames or email addresses for unsolicited messaging;</li>
          <li>Use the Services to compete with us or for any commercial enterprise;</li>
          <li>Sell or transfer your profile information.</li>
        </ul>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* 11. User Generated Contributions */}
      <div className="each-sec" id="sec-11">
        <h2 className="sec-heading heading-h2">11. USER GENERATED CONTRIBUTIONS</h2>
        <p>
          We may provide you with the opportunity to create, submit, post, display, transmit,
          perform, publish, distribute, or broadcast content and materials to us or on the
          Services, including but not limited to text, writings, video, audio, photographs,
          graphics, comments, suggestions, or personal information (collectively,
          "Contributions"). Contributions may be viewable by other users of the Services
          and through third-party websites.
        </p>
        <p>
          When you make Contributions, you represent and warrant that your Contributions:
        </p>
        <ul>
          <li>Do not infringe on any third party’s proprietary rights;</li>
          <li>You either own them or have the necessary rights and permissions;</li>
          <li>You have consent from any identifiable individuals included in your Contributions;</li>
          <li>Are not unauthorized advertising or spam;</li>
          <li>Are not obscene, harassing, defamatory, or otherwise objectionable;</li>
          <li>Do not violate any applicable law or regulation.</li>
        </ul>
        <p>
          Violation of these representations may result in termination or suspension of your
          rights to use the Services.
        </p>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* 12. Contribution License */}
      <div className="each-sec" id="sec-12">
        <h2 className="sec-heading heading-h2">12. CONTRIBUTION LICENSE</h2>
        <p>
          By posting your Contributions or making them accessible through linking your
          account from the Services to your social networking accounts, you automatically
          grant us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive,
          transferable, royalty-free, fully-paid, worldwide license to use, copy, reproduce,
          disclose, sell, resell, publish, broadcast, archive, store, display, reformat,
          translate, transmit, excerpt (in whole or in part), and distribute such Contributions
          for any purpose, and to prepare derivative works or incorporate your Contributions
          into other works.
        </p>
        <p>
          We do not claim ownership over your Contributions. You retain all intellectual
          property rights. However, you agree to hold us harmless for any claims related to
          your Contributions. We reserve the right, in our sole discretion, to edit, recategorize,
          or delete any Contributions at any time without notice.
        </p>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* 13. Guidelines for Reviews */}
      <div className="each-sec" id="sec-13">
        <h2 className="sec-heading heading-h2">13. GUIDELINES FOR REVIEWS</h2>
        <p>
          We may provide you areas on the Services to leave reviews or ratings. When posting
          a review, you must comply with the following criteria:
        </p>
        <ul>
          <li>You should have firsthand experience with the entity being reviewed.</li>
          <li>Your reviews should not contain offensive profanity or abusive, racist, or hateful language.</li>
          <li>Your reviews should not contain discriminatory references based on religion, race, gender, etc.</li>
          <li>Your reviews should not contain references to illegal activity.</li>
          <li>You should not be affiliated with competitors if posting negative reviews.</li>
          <li>You should not make false or misleading statements.</li>
          <li>You may not organize a campaign encouraging others to post reviews.</li>
        </ul>
        <p>
          We may accept, reject, or remove reviews at our sole discretion and are under no
          obligation to screen or delete reviews. By posting a review, you grant us a perpetual,
          non-exclusive, worldwide, royalty-free, fully paid, assignable, and sublicensable
          license to reproduce, modify, and distribute such content.
        </p>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* 14. Mobile Application License */}
      <div className="each-sec" id="sec-14">
        <h2 className="sec-heading heading-h2">14. MOBILE APPLICATION LICENSE</h2>
        <p>
          If you access the Services via the App, we grant you a revocable, non-exclusive,
          non-transferable, limited right to install and use the App on your wireless devices,
          and to access the Services strictly in accordance with these Legal Terms. You shall
          not decompile, reverse engineer, disassemble, decrypt, or modify the App except as
          permitted by law. You shall not remove or alter any proprietary notices on the App,
          use the App for any commercial enterprise, make it available on a network for
          multiple users, or exploit any proprietary information or interfaces for unauthorized
          development.
        </p>
        <p>
          <strong>Apple and Android Devices:</strong> The license granted to you for our App
          is limited to a non-transferable license to use the application on a device that
          utilizes Apple iOS or Android systems, in accordance with the App Distributor’s
          terms of service. You acknowledge that the App Distributor has no obligation to
          provide maintenance or support, and that in the event of any failure of the App to
          conform to any applicable warranty, you may notify the App Distributor for a refund
          (if applicable), but the distributor will have no other warranty obligation.
        </p>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* 15. Services Management */}
      <div className="each-sec" id="sec-15">
        <h2 className="sec-heading heading-h2">15. SERVICES MANAGEMENT</h2>
        <p>We reserve the right, but not the obligation, to:</p>
        <ul>
          <li>Monitor the Services for violations of these Legal Terms;</li>
          <li>Take legal action against anyone who violates these terms or applicable laws;</li>
          <li>
            Restrict, limit, or disable your access to the Services at our discretion (including
            removing or disabling any content that is burdensome or excessive);
          </li>
          <li>Manage the Services to protect our rights and ensure proper functioning.</li>
        </ul>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* 16. Privacy Policy */}
      <div className="each-sec" id="sec-16">
        <h2 className="sec-heading heading-h2">16. PRIVACY POLICY</h2>
        <p>
          We care about data privacy and security. By using the Services, you agree to be
          bound by our Privacy Policy posted on the Services, which is incorporated into
          these Legal Terms. Please be advised the Services are hosted in the United States.
          If you access the Services from any other region of the world with laws or other
          requirements governing personal data collection, use, or disclosure that differ
          from applicable laws in the United States, through your continued use of the
          Services, you are transferring your data to the United States, and you expressly
          consent to have your data transferred to and processed in the United States.
        </p>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* 17. Term and Termination */}
      <div className="each-sec" id="sec-17">
        <h2 className="sec-heading heading-h2">17. TERM AND TERMINATION</h2>
        <p>
          These Legal Terms shall remain in full force and effect while you use the Services.
          We reserve the right to deny access to or terminate your use of the Services
          (including blocking certain IP addresses) for any reason, including breach of these
          Legal Terms or applicable law, without notice or liability. If we terminate or
          suspend your account, you are prohibited from registering and creating a new
          account under your name or a fake name, or the name of any third party.
        </p>
        <p>
          In addition to terminating or suspending your account, we reserve the right to
          pursue appropriate legal remedies, including civil, criminal, and injunctive relief.
        </p>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* 18. Modifications and Interruptions */}
      <div className="each-sec" id="sec-18">
        <h2 className="sec-heading heading-h2">18. MODIFICATIONS AND INTERRUPTIONS</h2>
        <p>
          We reserve the right to change, modify, or remove the contents of the Services at
          any time or for any reason at our sole discretion without notice. We also reserve
          the right to modify or discontinue all or part of the Services without notice, and we
          will not be liable for any modification or discontinuance.
        </p>
        <p>
          We cannot guarantee the Services will be available at all times. You agree that we
          have no liability whatsoever for any loss, damage, or inconvenience caused by your
          inability to access or use the Services during any downtime or discontinuance.
        </p>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* 19. Governing Law */}
      <div className="each-sec" id="sec-19">
        <h2 className="sec-heading heading-h2">19. GOVERNING LAW</h2>
        <p>
          These Legal Terms and your use of the Services are governed by and construed in
          accordance with the laws of the State of Wyoming, without regard to its conflict of
          law principles.
        </p>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* 20. Dispute Resolution */}
      <div className="each-sec" id="sec-20">
        <h2 className="sec-heading heading-h2">20. DISPUTE RESOLUTION</h2>
        <p>
          Any legal action brought by either you or us shall be commenced or prosecuted in
          the state and federal courts located in Sheridan County, Wyoming. You and we
          consent to, and waive all defenses of lack of personal jurisdiction and forum non
          conveniens with respect to venue and jurisdiction in such courts. In no event shall
          any claim be commenced more than one (1) year after the cause of action arose.
        </p>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* 21. Corrections */}
      <div className="each-sec" id="sec-21">
        <h2 className="sec-heading heading-h2">21. CORRECTIONS</h2>
        <p>
          There may be typographical errors, inaccuracies, or omissions in the information on
          the Services. We reserve the right to correct any such errors and to update the
          information at any time without prior notice.
        </p>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* 22. Disclaimer */}
      <div className="each-sec" id="sec-22">
        <h2 className="sec-heading heading-h2">22. DISCLAIMER</h2>
        <p>
          THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
          AGREE THAT YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. TO THE
          FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
          EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS
          ABOUT THE ACCURACY OR COMPLETENESS OF THE CONTENT ON THE
          SERVICES OR ON ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE
          SERVICES, AND WE WILL NOT BE LIABLE FOR ANY ERRORS, DAMAGES, OR
          LOSSES ARISING FROM YOUR USE OF THE SERVICES.
        </p>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* 23. Limitations of Liability */}
      <div className="each-sec" id="sec-23">
        <h2 className="sec-heading heading-h2">23. LIMITATIONS OF LIABILITY</h2>
        <p>
          IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE
          LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
          CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
          DAMAGES, INCLUDING LOST PROFITS, LOST REVENUE, LOSS OF DATA, OR
          OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. OUR LIABILITY TO YOU
          FOR ANY CAUSE WHATSOEVER WILL AT ALL TIMES BE LIMITED TO THE
          AMOUNT PAID, IF ANY, BY YOU TO US.
        </p>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* 24. Indemnification */}
      <div className="each-sec" id="sec-24">
        <h2 className="sec-heading heading-h2">24. INDEMNIFICATION</h2>
        <p>
          You agree to defend, indemnify, and hold us harmless, including our subsidiaries,
          affiliates, and all of our respective officers, agents, partners, and employees, from
          and against any loss, damage, liability, claim, or demand, including reasonable
          attorneys’ fees and expenses, made by any third party due to or arising out of:
        </p>
        <ul>
          <li>Your use of the Services;</li>
          <li>A breach of these Legal Terms;</li>
          <li>Your violation of any rights of a third party, including intellectual property rights;</li>
          <li>Any harmful act you commit toward another user with whom you connected via the Services.</li>
        </ul>
        <p>
          We reserve the right, at your expense, to assume the exclusive defense and control
          of any matter for which you are required to indemnify us, and you agree to
          cooperate at your expense with our defense of such claims.
        </p>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* 25. User Data */}
      <div className="each-sec" id="sec-25">
        <h2 className="sec-heading heading-h2">25. USER DATA</h2>
        <p>
          We will maintain certain data that you transmit to the Services for the purpose of
          managing the performance of the Services, as well as data relating to your use of
          the Services. Although we perform routine backups of data, you are solely
          responsible for any data you transmit or that relates to your activities. You
          acknowledge that we shall have no liability to you for any loss or corruption of such
          data.
        </p>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* 26. Electronic Communications, Transactions, and Signatures */}
      <div className="each-sec" id="sec-26">
        <h2 className="sec-heading heading-h2">26. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h2>
        <p>
          Visiting the Services, sending us emails, and completing online forms constitute
          electronic communications. You consent to receive such communications and agree
          that all agreements, notices, disclosures, and other communications we provide to
          you electronically satisfy any legal requirement that such communication be in
          writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
          CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY
          OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR
          COMPLETED BY US OR VIA THE SERVICES. You waive any rights requiring
          non-electronic forms or signatures.
        </p>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* 27. SMS Text Messaging */}
      <div className="each-sec" id="sec-27">
        <h2 className="sec-heading heading-h2">27. SMS TEXT MESSAGING</h2>
        <p>
          <strong>Opting Out:</strong> If at any time you wish to stop receiving SMS messages
          from us, simply reply to the text with "STOP." You may receive an SMS message
          confirming your opt-out.
        </p>
        <p>
          <strong>Message and Data Rates:</strong> Please be aware that message and data
          rates may apply to any SMS messages sent or received, as determined by your
          carrier and mobile plan.
        </p>
        <p>
          <strong>Support:</strong> If you have any questions or need assistance regarding our
          SMS communications, please email{" "}
          <a className="content-link" href="mailto:support@tycoonland.nyc">
            support@tycoonland.nyc
          </a>{" "}
          or call (+1)3103409520.
        </p>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* 28. California Users and Residents */}
      <div className="each-sec" id="sec-28">
        <h2 className="sec-heading heading-h2">28. CALIFORNIA USERS AND RESIDENTS</h2>
        <p>
          If any complaint is not satisfactorily resolved, you may contact the Complaint
          Assistance Unit of the Division of Consumer Services of the California Department
          of Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112, Sacramento,
          California 95834, or by telephone at (800) 952-5210 or (916) 445-1254.
        </p>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* 29. Miscellaneous */}
      <div className="each-sec" id="sec-29">
        <h2 className="sec-heading heading-h2">29. MISCELLANEOUS</h2>
        <p>
          These Legal Terms and any policies or operating rules posted by us on the Services
          constitute the entire agreement and understanding between you and us. Our failure
          to exercise or enforce any right or provision of these Legal Terms shall not operate
          as a waiver of such right or provision. We may assign any or all of our rights and
          obligations to others at any time. We shall not be responsible or liable for any loss,
          damage, delay, or failure to act caused by any cause beyond our reasonable control.
          If any provision of these Legal Terms is deemed unlawful, void, or unenforceable,
          that provision is severable and does not affect the validity and enforceability of any
          remaining provisions. No joint venture, partnership, employment, or agency
          relationship is created between you and us as a result of these Legal Terms or use of
          the Services. You agree that these Legal Terms will not be construed against us by
          virtue of having drafted them. You hereby waive any and all defenses you may have
          based on the electronic form of these Legal Terms and the lack of signing by the
          parties hereto.
        </p>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* 30. Account Deletion and Digital Asset Forfeitures */}
      <div className="each-sec" id="sec-30">
        <h2 className="sec-heading heading-h2">30. ACCOUNT DELETION AND DIGITAL ASSET FORFEITURES</h2>
        <p>
          By requesting the deletion of your account, you acknowledge and agree to the
          following: If you hold any assets, digital or otherwise, within your account at the
          time of deletion, you forfeit all rights, claims, and ownership of those assets.
          Any such assets will be automatically reclaimed by Tycoonland, llc without
          compensation. Your remaining account balance, excluding forfeited assets, will be
          refunded to your default payment method on record, subject to any applicable
          processing fees. Account deletion is irreversible, and Tycoonland, llc will not be
          liable for any loss of access, revenue, or potential future earnings tied to the
          forfeited digital assets. It is your responsibility to liquidate or transfer any assets
          before initiating an account deletion request. Failure to do so will result in
          automatic forfeiture of digitized assets.
        </p>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />

      {/* Contact Us */}
      <div className="each-sec" id="sec-31">
        <h2 className="sec-heading heading-h2">CONTACT US</h2>
        <p>
          In order to resolve a complaint regarding the Services or to receive further
          information regarding use of the Services, please contact us at:
        </p>
        <p>
          Tycoonland, llc
          <br />
          1309 Coffeen Ave ste 1200, Sheridan, WY 82801, USA
          <br />
          Sheridan, WY 82801
          <br />
          United States
          <br />
          Phone: (+1)3103409520
          <br />
          Email:{" "}
          <a className="content-link" href="mailto:support@tycoonland.nyc">
            support@tycoonland.nyc
          </a>
        </p>
      </div>

      <ReturnToTop hashUrl="term1" category="Terms of Service" />
    </div>
  );
}

export default TermsOfUseFull;
